import '../styles/notFound.css';

export default function NotFound() {
  return (
    <section>
      <div className='not-found-content'>
        <h1 className='not-found-title'>404</h1>
        <p className='not-found-message'>Oops! Page not found</p>
        <p className='not-found-description'>
          The page you are looking for might be in another galaxy.
        </p>
        <a href='/login' className='not-found-link'>
          Go back home
        </a>
      </div>
    </section>
  );
}
