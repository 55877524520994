import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import CustomerManagement from './pages/userManagement/CustomerManagement';
import DispatcherManagement from './pages/userManagement/DispatcherManagement';
import TransactionHistory from './pages/wallet/TransactionHistory';
import DispatchManagement from './pages/scheduleHistory/DispatchManagement';
import ChangePassword from './pages/profile/ChangePassword';
import Notifications from './pages/profile/Notification';
import CustomerChats from './pages/scheduleHistory/CustomerChat';
import VerhicleTracking from './pages/userManagement/VerhicleTracking';
import PrivateRoute from './components/route/ProtectedRoute';
import Wallet from './pages/wallet/wallet';
import DispatcherWallet from './pages/wallet/DispatcherWallet';
import AdminManagement from './pages/userManagement/AdminManagement';
import Profile from './pages/profile/Profile';
import SignUp from './pages/SignUpPage';
import ForgotPassword from './pages/ForgotPasswordPage';
import ResetPassword from './pages/ResetPassword';
import AccessDenied from './pages/AccessDenied';
import NotFound from './pages/NotFound';
import Login from './pages/LoginPage';
import DashBoard from './pages/DashBoard';
import Index from './pages/DashBoardIndex';
import DispatcherFeedback from './pages/userManagement/DispatcherFeedBack';
import TaskManagement from './pages/taskManagement/Taskmanagement';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path='/dashboard'
          element={
            <PrivateRoute>
              <DashBoard />
            </PrivateRoute>
          }
        >
          <Route index element={<Index />} />
          <Route path='customer-management' element={<CustomerManagement />} />
          <Route
            path='dispatcher-management'
            element={<DispatcherManagement />}
          />
          <Route path='transaction-history' element={<TransactionHistory />} />
          <Route path='dispatch-management' element={<DispatchManagement />} />
          <Route path='change-password' element={<ChangePassword />} />
          <Route path='notifications' element={<Notifications />} />
          <Route path='customer-chat' element={<CustomerChats />} />
          <Route path='vericle-tracking' element={<VerhicleTracking />} />
          <Route path='wallet' element={<Wallet />} />
          <Route path='dispatcher-wallet' element={<DispatcherWallet />} />
          <Route path='admin-management' element={<AdminManagement />} />
          <Route path='profile' element={<Profile />} />
          <Route path='dispatcher-feedback' element={<DispatcherFeedback />} />
          <Route path='task-management' element={<TaskManagement />} />

        </Route>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/access-denied' element={<AccessDenied />} />

        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
