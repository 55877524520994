import axiosInstance from './axiosInstance';
import {
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_DISPATCHERS_SUCCESS,
  FETCH_DISPATCHERS_FAILURE,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USER_BY_ID_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_DISPATCHER_SUCCESS,
  UPDATE_DISPATCHER_FAILURE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  COUNT_CUSTOMERS,
  COUNT_CUSTOMERS_FAILURE,
  COUNT_DISPATCHERS,
  COUNT_DISPATCHERS_FAILURE,
  FETCH_ADMIN_SUPPORT_FAILURE,
  FETCH_ADMIN_SUPPORT_SUCCESS,
} from './actionTypes';

export const login = (email, password, toast, navigate) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post('/login', {
      email,
      password,
    });

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });

    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Login successful',
      life: 3000,
    });

    localStorage.setItem('adminInfo', JSON.stringify(data));

    if (data.role === 'admin' || data.role === 'support') {
      navigate('/dashboard');
      navigate(0);

    } else {
      setTimeout(() => {
        navigate('/access-denied');
      }, 100);
    }
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: error.response?.data || 'Login failed',
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('adminInfo');

  dispatch({
    type: LOGOUT,
  });
};

export const signUp =
  (email, userName, phone, password, toast, navigate) => async (dispatch) => {
    try {
      const response = await axiosInstance.post('/signup', {
        email,
        userName,
        phone,
        password,
      });

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: response.data,
      });

      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Signup successful',
        life: 3000,
      });

      setTimeout(() => {
        navigate('/login');
      }, 1500);
    } catch (error) {
      dispatch({
        type: SIGNUP_FAILURE,
        payload: error.response?.data || 'Sign-up failed',
      });
    }
  };

export const fetchCustomers =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.email = `/${regex.source}/${regex.flags}`;
      }

      const response = await axiosInstance.get('/users?role=user', {
        params: params,
      });

      dispatch({
        type: FETCH_CUSTOMERS_SUCCESS,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: FETCH_CUSTOMERS_FAILURE,
        payload: error.response?.data || 'Failed to fetch customers',
      });
    }
  };

export const countCustomers = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/users?role=user', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_CUSTOMERS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_CUSTOMERS_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchDispatchers =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.email = `/${regex.source}/${regex.flags}`;
      }

      const response = await axiosInstance.get('/users?role=dispatcher', {
        params: params,
      });

      dispatch({
        type: FETCH_DISPATCHERS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_DISPATCHERS_FAILURE,
        payload: error.response?.data || 'Failed to fetch dispatchers',
      });
    }
  };

export const countDispatchers = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/users?role=dispatcher', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_DISPATCHERS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_DISPATCHERS_FAILURE,
      payload: error.response?.data || 'Failed to fetch dispatcher',
    });
  }
};

export const fetchAdminsAndSupport =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
        role: ['admin', 'support'],
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.email = `/${regex.source}/${regex.flags}`;
      }

      const response = await axiosInstance.get('/users', {
        params: params,
      });

      dispatch({
        type: FETCH_ADMIN_SUPPORT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ADMIN_SUPPORT_FAILURE,
        payload:
          error.response?.data || 'Failed to fetch admin and support roles',
      });
    }
  };

export const countAdmins = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/users', {
      params: {
        _count: true,
        role: ['admin', 'support'],
      },
    });

    dispatch({
      type: FETCH_ADMINS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_ADMINS_FAILURE,
      payload: error.response?.data || 'Failed to fetch admins',
    });
  }
};

export const fetchUserById = (userId) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/users/${userId}`);

    const user = response.data;
    dispatch({
      type: FETCH_USER_BY_ID_SUCCESS,
      payload: user,
    });

    return user;
  } catch (error) {
    dispatch({
      type: FETCH_USER_BY_ID_FAILURE,
      payload: error.response?.data || 'Failed to fetch user',
    });
  }
};

export const updateCustomer =
  (customerId, updatedUserData) => async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `/users/${customerId}`,
        updatedUserData
      );

      dispatch({
        type: UPDATE_CUSTOMER_SUCCESS,
        payload: response.data,
      });

      dispatch(fetchCustomers());
    } catch (error) {
      dispatch({
        type: UPDATE_CUSTOMER_FAILURE,
        payload: error.response?.data || 'Failed to update customer',
      });
    }
  };

export const updateDispatcher =
  (dispatcherId, updatedUserData) => async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `/users/${dispatcherId}`,
        updatedUserData
      );

      dispatch({
        type: UPDATE_DISPATCHER_SUCCESS,
        payload: response.data,
      });

      dispatch(fetchDispatchers());
    } catch (error) {
      dispatch({
        type: UPDATE_DISPATCHER_FAILURE,
        payload: error.response?.data || 'Failed to update dispatcher',
      });
    }
  };
