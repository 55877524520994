import React from 'react';
import { Menu } from 'primereact/menu';
import '../styles/sideMenu.css';
export default function SideMenu({ isOpen }) {
  const items = [
    {
      label: 'Home',
      items: [
        {
          label: 'Dashboard',
          icon: 'pi pi-home',
          url: '/dashboard',
        },
      ],
    },
    {
      label: 'Wallet',
      items: [
        {
          label: 'Transaction History',
          icon: 'pi pi-history',
          url: '/dashboard/transaction-history',
        },
        {
          label: 'Wallets',
          icon: 'pi pi-wallet',
          url: '/dashboard/wallet',
        },
        {
          label: 'Dispatcher Wallets',
          icon: 'pi pi-wallet',
          url: '/dashboard/dispatcher-wallet',
        },
      ],
    },

    {
      label: 'User Management',
      items: [
        {
          label: 'Customer Management',
          icon: 'pi pi-users',
          url: '/dashboard/customer-management',
        },
        {
          label: 'Dispatcher Management',
          icon: 'pi pi-users',
          url: '/dashboard/dispatcher-management',
        },
        {
          label: 'Admin Management',
          icon: 'pi pi-user',
          url: '/dashboard/admin-management',
        },

        {
          label: 'Dispatcher / Verhicle Tracking',
          icon: 'pi pi-truck',
          url: '/dashboard/vericle-tracking',
        },
        {
          label: 'Dispatcher Feedback',
          icon: 'pi pi-book',
          url: '/dashboard/dispatcher-feedback',
        },
      ],
    },

    {
      label: 'Task Management',
      items: [
        {
          label: 'Task Management ',
          icon: 'pi pi-building',
          url: '/dashboard/task-management',
        },
      ],
    },
    {
      label: 'Schedule History',
      items: [
        {
          label: 'Customer/Dispatcher Chat',
          icon: 'pi pi-external-link',
          url: '/dashboard/customer-chat',
        },
        {
          label: 'Dispatch Management',
          icon: 'pi pi-server',
          url: '/dashboard/dispatch-management',
        },
      ],
    },

    {
      label: 'Profile',
      items: [
        {
          label: 'Account Management (password change, 2fa)',
          icon: 'pi pi-wrench',
          url: '/dashboard/change-password',
        },
        {
          label: 'Notifications',
          icon: 'pi pi-upload',
          url: '/dashboard/notifications',
        },
      ],
    },
  ];

  return (
    <div
      className={`fixed sidebar__menu__style ${isOpen ? 'hidden' : ''}`}
      style={{}}
    >
      <Menu model={items} className='menu__style' />
    </div>
  );
}
