import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';
import { useDispatch, useSelector } from 'react-redux';
import MovaexBike from '../../assets/movaex_bike.png';
import { fetchGPSLocations } from '../../actions/gpsLocationAction';

const Map = ({ riders }) => {
  const mapContainerStyle = {
    height: '600px',
    width: '100%',
    margin: '20px auto',
  };

  const [addresses, setAddresses] = useState([]);
  const [iconsLoaded, setIconsLoaded] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const addressPromises = riders.map((rider) =>
          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=${encodeURIComponent(
              process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            )}&latlng=${encodeURIComponent(
              rider.location.coordinates[1] +
                ',' +
                rider.location.coordinates[0]
            )}`
          ).then((response) => response.json())
        );

        const resolvedAddresses = await Promise.all(addressPromises);
        setAddresses(resolvedAddresses);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }, [riders]);

  const handleLoadIcons = () => {
    setIconsLoaded(true);
  };

  // Find the coordinates of the location with the most riders
  const centerCoordinates =
    riders.length > 0
      ? riders.reduce((prev, curr) => (prev.count > curr.count ? prev : curr))
          .location.coordinates
      : [6.4415, 7.5086];

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      onLoad={handleLoadIcons}
      async
    >
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={{ lat: centerCoordinates[1], lng: centerCoordinates[0] }}
        zoom={12}
      >
        {riders.map((rider, index) => (
          <Marker
            key={rider._id}
            position={{
              lat: rider.location.coordinates[1],
              lng: rider.location.coordinates[0],
            }}
            icon={{
              url: MovaexBike,
              scaledSize: iconsLoaded ? { width: 40, height: 40 } : null,
            }}
          >
            {addresses[index] && (
              <InfoWindow
                position={{
                  lat: rider.location.coordinates[1],
                  lng: rider.location.coordinates[0],
                }}
              >
                <div>
                  <p>{addresses[index].results[0]?.formatted_address}</p>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

const App = () => {
  const dispatch = useDispatch();
  const riders = useSelector((state) => state.gpsLocation.gpsLocations);

  useEffect(() => {
    dispatch(fetchGPSLocations());

    const intervalId = setInterval(() => {
      dispatch(fetchGPSLocations());
    }, 60000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div style={{ textAlign: 'center' }}>
    
      <Map riders={riders} />
    </div>
  );
};

export default App;
