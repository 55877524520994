import {
    FETCH_GPS_LOCATIONS_SUCCESS,
    FETCH_GPS_LOCATIONS_FAILURE,
  } from '../actions/actionTypes';
  
  const initialState = {
    gpsLocations: [],
    error: null,
  };
  
  const gpsLocationReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_GPS_LOCATIONS_SUCCESS:
        return {
          ...state,
          gpsLocations: action.payload,
          error: null,
        };
  
      case FETCH_GPS_LOCATIONS_FAILURE:
        return {
          ...state,
          gpsLocations: [],
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default gpsLocationReducer;
  