import {
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_SINGLE_TRANSACTION_SUCCESS,
  FETCH_SINGLE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  COUNT_TRANSACTIONS,
  COUNT_TRANSACTIONS_FAILURE,
  FETCH_ACCOUNT_TRANSACTION,
  FETCH_ACCOUNT_TRANSACTION_FAILURE,
  COUNT_ACCOUNT_TRANSACTION,
} from '../actions/actionTypes';

const initialState = {
  transactions: [],
  singleTransactionRequest: null,
  error: null,
  countTransaction: null,
  accountTransactions: [],
  countAccountTransaction: null,
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload,
        error: null,
      };

    case FETCH_TRANSACTIONS_FAILURE:
      return {
        ...state,
        transactions: [],
        error: action.payload,
      };

    case FETCH_SINGLE_TRANSACTION_SUCCESS:
      return {
        ...state,
        singleTransactionRequest: action.payload,
        error: null,
      };

    case FETCH_SINGLE_TRANSACTION_FAILURE:
      return {
        ...state,
        singleTransactionRequest: null,
        error: action.payload,
      };

    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case UPDATE_TRANSACTION_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case COUNT_TRANSACTIONS:
      return {
        ...state,
        countTransaction: action.payload,
        error: null,
      };
    case COUNT_TRANSACTIONS_FAILURE:
      return {
        ...state,
        countTransaction: 0,
        error: action.payload,
      };

    case COUNT_ACCOUNT_TRANSACTION:
      return {
        ...state,
        countAccountTransaction: action.payload,
        error: action.payload,
      };

    case FETCH_ACCOUNT_TRANSACTION:
      return {
        ...state,
        accountTransactions: action.payload,
        error: null,
      };

    case FETCH_ACCOUNT_TRANSACTION_FAILURE:
      return {
        ...state,
        accountTransactions: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default transactionReducer;
