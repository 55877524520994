import axiosInstance from './axiosInstance';
import {
  FETCH_GPS_LOCATIONS_FAILURE,
  FETCH_GPS_LOCATIONS_SUCCESS,
} from './actionTypes';

export const fetchGPSLocationsSuccess = (locations) => ({
  type: FETCH_GPS_LOCATIONS_SUCCESS,
  payload: locations,
});

export const fetchGPSLocationsFailure = (error) => ({
  type: FETCH_GPS_LOCATIONS_FAILURE,
  payload: error,
});

export const fetchGPSLocations = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/gpsLocations', {
      params: {
        _sort: 'createdAt:desc',
      },
    });

    dispatch(fetchGPSLocationsSuccess(response.data));
  } catch (error) {
    dispatch(
      fetchGPSLocationsFailure(
        error.response?.data || 'Failed to fetch GPS locations'
      )
    );
  }
};
