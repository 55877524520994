// actionTypes.js

// Authentication Actions
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// User Actions
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const FETCH_DISPATCHERS_SUCCESS = 'FETCH_DISPATCHERS_SUCCESS';
export const FETCH_DISPATCHERS_FAILURE = 'FETCH_DISPATCHERS_FAILURE';
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS';
export const FETCH_USER_BY_ID_FAILURE = 'FETCH_USER_BY_ID_FAILURE';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';
export const UPDATE_DISPATCHER_SUCCESS = 'UPDATE_DISPATCHER_SUCCESS';
export const UPDATE_DISPATCHER_FAILURE = 'UPDATE_DISPATCHER_FAILURE';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILURE = 'FETCH_ADMINS_FAILURE';
export const COUNT_CUSTOMERS = 'COUNT_CUSTOMERS';
export const COUNT_CUSTOMERS_FAILURE = 'COUNT_CUSTOMERS_FAILURE';
export const COUNT_DISPATCHERS = 'COUNT_DISPATCHERS';
export const COUNT_DISPATCHERS_FAILURE = 'COUNT_DISPATCHERS_FAILURE';
export const FETCH_ADMIN_SUPPORT_FAILURE = 'FETCH_ADMIN_SUPPORT_FAILURE';
export const FETCH_ADMIN_SUPPORT_SUCCESS = 'FETCH_ADMIN_SUPPORT_SUCCESS';

// Dispatch Request Actions
export const FETCH_DISPATCHER_REQUESTS_SUCCESS =
  'FETCH_DISPATCHER_REQUESTS_SUCCESS';
export const FETCH_DISPATCHER_REQUESTS_FAILURE =
  'FETCH_DISPATCHER_REQUESTS_FAILURE';
export const FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS =
  'FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS';
export const FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE =
  'FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE';
export const UPDATE_DISPATCHER_REQUEST_SUCCESS =
  'UPDATE_DISPATCHER_REQUEST_SUCCESS';
export const UPDATE_DISPATCHER_REQUEST_FAILURE =
  'UPDATE_DISPATCHER_REQUEST_FAILURE';
export const COUNT_DISPATCHERREQUESTS = 'COUNT_DISPATCHERREQUESTS';
export const COUNT_DISPATCHERREQUESTS_FAILURE =
  'COUNT_DISPATCHERREQUESTS_FAILURE';
export const FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS =
  'FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS';
export const FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE =
  'FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE';
export const COUNT_ACCOUNT_DISPATCH_REQUESTS =
  'COUNT_ACCOUNT_DISPATCH_REQUESTS';
export const COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE =
  'COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE';

// Location  Actions
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';
export const FETCH_SINGLE_LOCATION_SUCCESS = 'FETCH_SINGLE_LOCATION_SUCCESS';
export const FETCH_SINGLE_LOCATION_FAILURE = 'FETCH_SINGLE_LOCATION_FAILURE';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';

// Transaction Actions
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';
export const FETCH_SINGLE_TRANSACTION_SUCCESS =
  'FETCH_SINGLE_TRANSACTION_SUCCESS';
export const FETCH_SINGLE_TRANSACTION_FAILURE =
  'FETCH_SINGLE_TRANSACTION_FAILUREc';
export const UPDATE_TRANSACTION_SUCCESS = 'UPDATE_TRANSACTION_SUCCESS';
export const UPDATE_TRANSACTION_FAILURE = 'UPDATE_TRANSACTION_FAILURE';
export const COUNT_TRANSACTIONS = 'COUNT_TRANSACTIONS';
export const COUNT_TRANSACTIONS_FAILURE = 'COUNT_TRANSACTIONS_FAILURE';
export const FETCH_ACCOUNT_TRANSACTION = 'FETCH_ACCOUNT_TRANSACTION';
export const COUNT_ACCOUNT_TRANSACTION = 'COUNT_ACCOUNT_TRANSACTION';
export const FETCH_ACCOUNT_TRANSACTION_FAILURE =
  'FETCH_ACCOUNT_TRANSACTION_FAILURE';

// Gps Location Actions
export const FETCH_GPS_LOCATIONS_SUCCESS = 'FETCH_GPS_LOCATIONS_SUCCESS';
export const FETCH_GPS_LOCATIONS_FAILURE = 'FETCH_GPS_LOCATIONS_FAILURE';

// Wallet Actions
export const FETCH_WALLET_SUCCESS = 'FETCH_WALLET_SUCCESS';
export const FETCH_WALLET_FAILURE = 'FETCH_WALLET_FAILURE';
export const FETCH_WALLETS_SUCCESS = 'FETCH_WALLETS_SUCCESS';
export const FETCH_WALLETS_FAILURE = 'FETCH_WALLETS_FAILURE';
export const FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS =
  'FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS';
export const FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE =
  'FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE';
export const FETCH_WALLETS_WITHDRAWAL_SUCCESS =
  'FETCH_WALLETS_WITHDRAWAL_SUCCESS';
export const FETCH_WALLETS_WITHDRAWAL_FAILURE =
  'FETCH_WALLETS_WITHDRAWAL_FAILURE';
export const COUNT_WALLETS = 'COUNT_WALLETS';
export const COUNT_WALLETS_FAILURE = 'COUNT_WALLETS_FAILURE';
export const FETCH_ACCOUNT_WALLETS = 'FETCH_ACCOUNT_WALLETS';
export const FETCH_ACCOUNT_WALLETS_FAILURE = 'FETCH_ACCOUNT_WALLETS_FAILURE';

// User Rating Actions

export const FETCH_USER_RATING_SUCCESS = 'FETCH_USER_RATING_SUCCESS';
export const FETCH_USER_RATING_FAILURE = 'FETCH_USER_RATING_FAILURE';
export const COUNT_USER_RATING_SUCCESS = 'COUNT_USER_RATING_SUCCESS';
export const COUNT_USER_RATING_FAILURE = 'COUNT_USER_RATING_FAILURE';

// Rating Actions
export const FETCH_RATING_SUCCESS = 'FETCH_RATING_SUCCESS';
export const FETCH_RATING_FAILURE = 'FETCH_RATING_FAILURE';
export const COUNT_RATING_SUCCESS = 'COUNT_RATING_SUCCESS';
export const COUNT_RATING_FAILURE = 'COUNT_RATING_FAILURE';

//Task Management Actions
export const FETCH_TASK_MANAGEMENT_SUCCESS = 'FETCH_TASK_MANAGEMENT_SUCCESS';
export const FETCH_TASK_MANAGEMENT_FAILURE = 'FETCH_TASK_MANAGEMENT_FAILURE';
export const COUNT_TASK_MANAGEMENT_SUCCESS = 'COUNT_TASK_MANAGEMENT_SUCCESS';
export const COUNT_TASK_MANAGEMENT_FAILURE = 'COUNT_TASK_MANAGEMENT_FAILURE';
export const FETCH_TASK_PRIORITY_LIST_SUCCESS = 'FETCH_TASK_PRIORITY_LIST_SUCCESS';
export const FETCH_TASK_PRIORITY_LIST_FAILURE = 'FETCH_TASK_PRIORITY_LIST_FAILURE';
export const COUNT_TASK_PRIORITY_LIST_SUCCESS = 'COUNT_TASK_PRIORITY_LIST_SUCCESS';
export const COUNT_TASK_PRIORITY_LIST_FAILURE = 'COUNT_TASK_PRIORITY_LIST_FAILURE';
