import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../styles/walletCard.css';
import {
  fetchWalletsAccount,
  fetchWalletsdispatcherAccount,
  fetchWithdrawalWalletsAccount,
} from '../actions/walletActions';
import {
  countAdmins,
  countCustomers,
  countDispatchers,
} from '../actions/authActions';
import { countDispatcherRequests } from '../actions/dispatcherRequestActions';

export default function DashBoardCard() {
  const dispatch = useDispatch();
  const totalDispatcherRequestsCount = useSelector(
    (state) => state.dispatcherRequest.countDispatcherRequests || 0
  );

  const totalDispatcherCount = useSelector(
    (state) => state.auth.countDispatcher || 0
  ); 

  const totalCustomerCount = useSelector((state) => state.auth.count);

  const withDrawalWallets = useSelector(
    (state) => state.wallet.withdrawalAccount
  );
  const sortedWallets = useSelector((state) => state.wallet.sortedWallets);
  const sorteddispatchAccounts = useSelector(
    (state) => state.wallet.dispatchAccounts
  );

  const displayWithdrawalAvailableBalance =
    withDrawalWallets.length > 0
      ? withDrawalWallets[0].displayAvailableBalance
      : 0;

  const displayAvailableBalance =
    sortedWallets.length > 0 ? sortedWallets[0].displayAvailableBalance : null;

  const displayAvailableBalanceForDispatchAccount =
    sorteddispatchAccounts.length > 0
      ? sorteddispatchAccounts[0].displayAvailableBalance
      : 0;

  const admins = useSelector((state) => state.auth.adminCount);

  useEffect(() => {
    const fetchData = () => {
      dispatch(countDispatchers());
      dispatch(countDispatcherRequests());
      dispatch(fetchWalletsAccount());
      dispatch(countAdmins());
      dispatch(countCustomers());
      dispatch(fetchWalletsdispatcherAccount());
      dispatch(fetchWithdrawalWalletsAccount());
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 30 * 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <div className='grid '>
      <div className='col-12 md:col-4 lg:col-4 xl:col-4'>
        <div className='wallet-card'>
          <div className='wallet-card-header'>
            <h3>COLLECTION ACCOUNT </h3>
          </div>
          <div className='wallet-card-body'>
            <div className='wallet-wallet-info'>
              <p className='wallet-balance-label'>Balance</p>
              <h3 className='wallet-balance-amount'>
                NGN {displayAvailableBalance}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 md:col-4 lg:col-4 xl:col-4'>
        <div className='wallet-card'>
          <div className='wallet-card-header'>
            <h3>DISPATCH_PAYMENT ACCOUNT</h3>
          </div>
          <div className='wallet-card-body'>
            <div className='wallet-wallet-info'>
              <p className='wallet-balance-label'>Balance</p>
              <h3 className='wallet-balance-amount'>
                NGN {displayAvailableBalanceForDispatchAccount}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 md:col-4 lg:col-4 xl:col-4'>
        <div className='wallet-card'>
          <div className='wallet-card-header'>
            <h3>WITHDRAWALS ACCOUNT</h3>
          </div>
          <div className='wallet-card-body'>
            <div className='wallet-wallet-info'>
              <p className='wallet-balance-label'>Balance</p>
              <h3 className='wallet-balance-amount'>
                NGN {displayWithdrawalAvailableBalance}
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className='col-12 md:col-6 lg:col-4 xl:col-3'>
        <div className='surface-0  p-3 border-1 border-300 border-round'>
          <div className='flex justify-content-between mb-3'>
            <div>
              <span className='block text-500 font-semibold mb-3'>
                Dispatch Requests
              </span>
              <div className='text-900 font-bold text-xl'>
                {' '}
                {totalDispatcherRequestsCount}
              </div>
            </div>
            <div
              className='flex align-items-center justify-content-center div__icon___color border-round'
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className='pi pi-shopping-cart icon___color text-xl'></i>
            </div>
          </div>
          <span className='text-500'>
            Thank you for being a part of our community!
          </span>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-4 xl:col-3'>
        <div className='surface-ground  p-3 border-1 border-300 border-round'>
          <div className='flex justify-content-between mb-3'>
            <div>
              <span className='block text-500 font-semibold mb-3'>Admins</span>
              <div className='text-900 font-bold text-xl'>{admins}</div>
            </div>
            <div
              className='flex align-items-center justify-content-center div__icon___color border-round'
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className='pi pi-shopping-cart icon___color text-xl'></i>
            </div>
          </div>
          <span className='text-500'>
            Thank you for being a part of our community!
          </span>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-4 xl:col-3'>
        <div className='surface-ground  p-3 border-1 border-300 border-round'>
          <div className='flex justify-content-between mb-3'>
            <div>
              <span className='block text-500 font-semibold mb-3'>
                Customers
              </span>
              <div className='text-900 font-bold text-xl'>
                {totalCustomerCount}
              </div>
            </div>
            <div
              className='flex align-items-center justify-content-center div__icon___color border-round'
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className='pi pi-shopping-cart icon___color text-xl'></i>
            </div>
          </div>
          <span className='text-500'>
            Thank you for being a part of our community!
          </span>
        </div>
      </div>
      <div className='col-12 md:col-6 lg:col-4 xl:col-3'>
        <div className='surface-ground  p-3 border-1 border-300 border-round'>
          <div className='flex justify-content-between mb-3'>
            <div>
              <span className='block text-500 font-semibold mb-3'>
                Dispatchers
              </span>
              <div className='text-900 font-bold text-xl'>
                {totalDispatcherCount}
              </div>
            </div>
            <div
              className='flex align-items-center justify-content-center div__icon___color border-round'
              style={{ width: '2.5rem', height: '2.5rem' }}
            >
              <i className='pi pi-shopping-cart icon___color text-xl'></i>
            </div>
          </div>
          <span className='text-500'>
            Thank you for being a part of our community!
          </span>
        </div>
      </div>
    </div>
  );
}
