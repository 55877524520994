import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Rating } from 'primereact/rating';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchUserRatings,
  countRatings,
} from '../../actions/userRatingActions';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { fetchUserById } from '../../actions/authActions';

export default function DispatcherFeedback() {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const ratings = useSelector((state) => state.userRating.userRatings);
  const totalCount = useSelector((state) => state.userRating.countUserRating);

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchUserRatings(currentPage, pageSize, totalCount, searchTerm)
        );
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchUserRatings(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedProducts = await Promise.all(
          ratings.map(async (rating) => {
            let accountUserPromise;
            let ratedUserPromise;

            if (rating.account && rating.account.length === 24) {
              const accountUser = await dispatch(fetchUserById(rating.account));
              const ratedUser = await dispatch(
                fetchUserById(rating.ratedUserID)
              );
              accountUserPromise = `${accountUser?.firstName} ${accountUser?.lastName}`;
              ratedUserPromise = `${ratedUser?.firstName} ${ratedUser?.lastName}`;
            } else {
              accountUserPromise = rating.account;
              ratedUserPromise = rating.ratedUserID;
            }

            const [accountUser, ratedUser] = await Promise.all([
              accountUserPromise,
              ratedUserPromise,
            ]);

            return {
              id: rating._id,
              account: accountUser,
              ratingType: rating?.ratingType || '',
              note: rating?.note || '',
              ratedUserID: ratedUser,
              rating: rating?.rating,
              createdAt: rating?.createdAt
                ? new Date(rating?.createdAt).toLocaleString('en-US', {
                    dateStyle: 'full',
                    timeStyle: 'short',
                  })
                : '',
            };
          })
        );
        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch, ratings]);

  useEffect(() => {
    dispatch(countRatings());
  }, [dispatch]);

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const ratingBodyTemplate = (product) => {
    return <Rating value={product.rating} readOnly cancel={false} />;
  };

  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0 uppercase'>Feedbacks</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(fetchUserRatings(currentPage, pageSize, totalCount));
            }
          }}
          placeholder='Search for note...'
        />
      </span>
    </div>
  );

  const footer = `In total there ${
    totalCount > 0 ? `are ${totalCount} Feedbacks` : 'are 0 Feedback'
  }.`;

  return (
    <>
      <div className='card'>
        <DataTable
          value={products}
          header={header}
          size='small'
          footer={footer}
          rows={pageSize}
          dataKey='id'
          tableStyle={{ minWidth: '100%' }}
        >
          <Column field='account' header='Customer Name'></Column>
          <Column field='ratedUserID' header='Rated Rider'></Column>
          <Column field='note' header='Note'></Column>
          <Column field='ratingType' header='Rating Type'></Column>
          <Column
            field='rating'
            header='Rating'
            body={ratingBodyTemplate}
          ></Column>
          <Column field='createdAt' header='Date'></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />
      </div>
    </>
  );
}
