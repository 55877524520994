import {
  FETCH_TASK_MANAGEMENT_SUCCESS,
  FETCH_TASK_MANAGEMENT_FAILURE,
  COUNT_TASK_MANAGEMENT_SUCCESS,
  COUNT_TASK_MANAGEMENT_FAILURE,
  FETCH_TASK_PRIORITY_LIST_SUCCESS,
  FETCH_TASK_PRIORITY_LIST_FAILURE,
  COUNT_TASK_PRIORITY_LIST_SUCCESS,
  COUNT_TASK_PRIORITY_LIST_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  dispatchTasks: [],
  taskPriorityLists: [],
  countDispatchTask: null,
  countTaskPriorityList: null,
  error: null,
};

const dispatchTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TASK_MANAGEMENT_SUCCESS:
      return {
        ...state,
        dispatchTasks: action.payload,
        error: null,
      };

    case FETCH_TASK_MANAGEMENT_FAILURE:
      return {
        ...state,
        dispatchTasks: [],
        error: action.payload,
      };

    case COUNT_TASK_MANAGEMENT_SUCCESS:
      return {
        ...state,
        countDispatchTask: action.payload,
        error: null,
      };
    case COUNT_TASK_MANAGEMENT_FAILURE:
      return {
        ...state,
        countDispatchTask: 0,
        error: action.payload,
      };

    case FETCH_TASK_PRIORITY_LIST_SUCCESS:
      return {
        ...state,
        taskPriorityLists: action.payload,
        error: null,
      };

    case FETCH_TASK_PRIORITY_LIST_FAILURE:
      return {
        ...state,
        taskPriorityLists: [],
        error: action.payload,
      };

    case COUNT_TASK_PRIORITY_LIST_SUCCESS:
      return {
        ...state,
        countTaskPriorityList: action.payload,
        error: null,
      };
    case COUNT_TASK_PRIORITY_LIST_FAILURE:
      return {
        ...state,
        countTaskPriorityList: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dispatchTaskReducer;
