import { combineReducers } from 'redux';
import authReducer from '../reducers/authReducer';
import dispatcherRequestReducer from '../reducers/dispatcherRequestReducer';
import transactionReducer from '../reducers/transactionReducer';
import gpsLocationReducer from '../reducers/gpsLocationReducer';
import walletReducer from '../reducers/walletReducer';
import userRatingReducer from '../reducers/userRatingReducer';
import ratingReducer from '../reducers/userRatingReducer';
import dispatchTaskReducer from '../reducers/taskManagementReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  userRating: userRatingReducer,
  dispatcherRequest: dispatcherRequestReducer,
  transaction: transactionReducer,
  gpsLocation: gpsLocationReducer,
  wallet: walletReducer,
  rating: ratingReducer,
  dispatchTask: dispatchTaskReducer,
});

export default rootReducer;
