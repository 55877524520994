import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useState, useRef, useEffect } from 'react';
import movaexLogo from '../assets/movaexLogo.png';
import '../styles/login.css';
import { useDispatch, useSelector } from 'react-redux';
import { signUp } from '../actions/authActions';
import { useNavigate } from 'react-router-dom';

export default function SignUp() {
  const dispatch = useDispatch();
  const signUpError = useSelector((state) => state.auth.error);

  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const toast = useRef(null);

  useEffect(() => {
    if (toast.current) {
      if (signUpError && typeof signUpError === 'object') {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: signUpError.message || 'Sigup failed',
          life: 3000,
        });
      } else if (signUpError) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: signUpError || 'Sigup failed',
          life: 3000,
        });
      }
    }
  }, [signUpError]);

  const navigate = useNavigate();
  const handleSignUp = (e) => {
    e.preventDefault();
    dispatch(signUp(email, userName, phone, password, toast, navigate));
  };

  return (
    <section>
      <div className='flex align-items-center my-auto h-screen justify-content-center'>
        <div className='surface-card p-4 shadow-2 border-round w-full md:w-6 lg:w-5 xl:w-4'>
          <div className='text-center mb-5'>
            <img src={movaexLogo} alt='hyper' height={50} className='mb-3' />
            <div className='text-900 text-3xl font-medium mb-3'>
              Welcome Back
            </div>
            <span className='text-600 font-medium line-height-3 '>
              Already have an account?
            </span>
            <a
              href='/login'
              className='font-medium no-underline ml-2 login__span__style cursor-pointer'
            >
              Login here
            </a>
          </div>

          <div>
            <label htmlFor='email' className='block text-900 font-medium mb-2'>
              Email Address
            </label>
            <InputText
              id='email'
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className='w-full mb-3 login__border_style'
            />

            <label
              htmlFor='username'
              className='block text-900 font-medium mb-2'
            >
              Your Name
            </label>
            <InputText
              id='userName'
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className='w-full mb-3 login__border_style'
            />

            <label htmlFor='phone' className='block text-900 font-medium mb-2'>
              Phone Number
            </label>
            <InputText
              id='phone'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className='w-full mb-3 login__border_style'
            />

            <label
              htmlFor='password'
              className='block text-900 font-medium mb-2'
            >
              Create a Password
            </label>
            <InputText
              id='password'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className='w-full mb-3 login__border_style'
            />

            <Button
              label='Continue'
              icon='pi pi-user'
              onClick={handleSignUp}
              className='w-full button__style'
              disabled={!email || !password || !phone || !userName}
            />
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </section>
  );
}
