import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { wallets } from '../../actions/walletActions';
import {
  getTransactions,
  countTransaction,
} from '../../actions/transactionActions';
import { fetchDispatchers, countDispatchers } from '../../actions/authActions';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import '../../styles/dispatcherWallet.css';
import { getSignedURL } from '../../actions/avatarService';

export default function CustomerManagement() {
  const [visible, setVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [firstTransaction, setFirstTransaction] = useState(0);
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const [pageSize] = useState(10);
  const [pageSizeT] = useState(10);
  const [products, setProducts] = useState([]);
  const [first, setFirst] = useState(0);
  const dispatch = useDispatch();
  const totalCount = useSelector((state) => state.auth.countDispatcher);
  const totalCountT = useSelector(
    (state) => state.transaction.countAccountTransaction
  );

  const users = useSelector((state) => state.auth.dispatchers);
  const transactions = useSelector(
    (state) => state.transaction.accountTransactions
  );

  useEffect(() => {
    dispatch(fetchDispatchers(currentPage, pageSize, totalCount));
    dispatch(countDispatchers());
  }, [dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(countTransaction(selectedUserId));
      dispatch(
        getTransactions(
          currentPageTransaction,
          pageSizeT,
          selectedUserId,
          totalCountT
        )
      );
    }
  }, [
    dispatch,
    selectedUserId,
    currentPageTransaction,
    pageSizeT,
    totalCountT,
  ]);

  const useAvatarFetch = (avatarUrl) => {
    const [avatarURL, setAvatarURL] = useState(null);
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    useEffect(() => {
      const fetchAvatarURL = async () => {
        try {
          setLoadingAvatar(true);
          const { url } = await getSignedURL(avatarUrl);
          setAvatarURL(url);
        } catch (error) {
        } finally {
          setLoadingAvatar(false);
        }
      };

      if (avatarUrl) {
        fetchAvatarURL();
      }
    }, [avatarUrl]);

    return { avatarURL, loadingAvatar };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!users) {
          console.warn('No users found.');
          return;
        }
        const updatedProducts = await Promise.all(
          users.map(async (user) => {
            let defaultPromise;
            let cashCollectionPromise;
            let dispatcherCommissionPromise;

            if (user && user._id.length === 24) {
              const defaultResponse = await dispatch(wallets(user._id));

              const cashCollectionResponse = await dispatch(
                wallets(user._id, 'cash_collection')
              );

              const dispatcherCommissionResponse = await dispatch(
                wallets(user._id, 'dispatcher_commission')
              );

              defaultPromise = defaultResponse;
              cashCollectionPromise = cashCollectionResponse;
              dispatcherCommissionPromise = dispatcherCommissionResponse;
            } else {
              defaultPromise = user._id;
              cashCollectionPromise = user._id;
              dispatcherCommissionPromise = user._id;
            }

            const [defaultP, cashCollectionInfo, dispatcherCommissionInfo] =
              await Promise.all([
                defaultPromise,
                cashCollectionPromise,
                dispatcherCommissionPromise,
              ]);

            return {
              id: user._id,
              walletInfo: `NGN ${
                defaultP[0]?.displayAvailableBalance !== undefined
                  ? defaultP[0]?.displayAvailableBalance
                  : 0
              }`,

              dispatcherCommissionInfo: `NGN ${
                dispatcherCommissionInfo[0]?.displayAvailableBalance !==
                undefined
                  ? dispatcherCommissionInfo[0]?.displayAvailableBalance
                  : 0
              }`,

              cashCollectionInfo: `NGN ${
                cashCollectionInfo[0]?.displayAvailableBalance !== undefined
                  ? cashCollectionInfo[0]?.displayAvailableBalance
                  : 0
              }`,

              firstName: user.firstName || '',
              lastName: user.lastName || '',
              email: user.email,
              avatar: { url: user.avatar.url },
              createdAt: new Date(user.createdAt).toLocaleDateString(),
              status: user.status,
              role: user.role,
              verified: user.verificationStatus,
            };
          })
        );
        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch, users]);

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const handlePageChangeT = (event) => {
    setFirstTransaction(event.first);
    setCurrentPageTransaction(event.page + 1);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-external-link'
          rounded
          outlined
          className='mr-2'
          onClick={() => {
            setVisible(true);
            setSelectedUserId(rowData.id);
            setSelectedUserName(`${rowData.firstName} ${rowData.lastName}`);
          }}
        />
      </React.Fragment>
    );
  };

  const AvatarComponent = ({ rowData }) => {
    const { avatarURL, loadingAvatar } = useAvatarFetch(rowData.avatar?.url);

    return (
      <div className='flex align-items-center gap-2'>
        {loadingAvatar ? (
          <span>Loading...</span>
        ) : (
          <>
            {rowData.avatar && rowData.avatar.url ? (
              <img
                alt={rowData.firstName}
                src={avatarURL}
                className='avatar___image'
              />
            ) : (
              <Avatar
                label={
                  rowData.firstName
                    ? rowData.firstName.slice(0, 1).toUpperCase()
                    : ''
                }
                size='medium'
                shape='circle'
              />
            )}
          </>
        )}
        <span>
          {rowData.firstName} <span></span> {rowData.lastName}
        </span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => (
    <AvatarComponent rowData={rowData} />
  );

  const footer = `In total there ${
    totalCount > 0 ? `are ${totalCount} Wallets` : 'are 0 Wallet'
  }.`;

  const transactionFooter = `In total there ${
    totalCountT > 0 ? `are ${totalCountT} Transactions` : 'are 0 Transaction'
  }.`;
  return (
    <>
      <div className='card'>
        <DataTable
          value={products}
          tableStyle={{ minWidth: '100%' }}
          dataKey='id'
          size='small'
          rows={pageSize}
          header='DISPATCHER WALLET'
          footer={footer}
        >
          <Column
            field='representative.name'
            header='Name'
            body={representativeBodyTemplate}
          />

          <Column field='email' header='Email'></Column>
          <Column field='walletInfo' header='Ballance'></Column>
          <Column field='cashCollectionInfo' header='Cash Collected'></Column>
          <Column
            field='dispatcherCommissionInfo'
            header='Commission on Trip'
          ></Column>
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />
      </div>

      <div className='card flex justify-content-center'>
        <Dialog
          visible={visible}
          style={{ width: '70%' }}
          onHide={() => setVisible(false)}
        >
          <div className='card mt-5'>
            <DataTable
              header={`TRANSACTION HISTORY${
                selectedUserId ? ` - ${selectedUserName}` : ''
              }`}
              footer={transactionFooter}
              value={transactions}
              size='small'
              rows={pageSizeT}
              tableStyle={{ minWidth: '100%' }}
            >
              <Column field='description' header='Description'></Column>
              <Column field='type' header='Type'></Column>
              <Column
                field='displayAmount'
                header='Amount'
                body={(rowData) => `NGN ${rowData.displayAmount}`}
              ></Column>
              <Column
                field='createdAt'
                header='Date'
                body={(rowData) =>
                  new Date(rowData.createdAt).toLocaleString('en-US', {
                    dateStyle: 'full',
                    timeStyle: 'short',
                  })
                }
              ></Column>
            </DataTable>
            <Paginator
              first={firstTransaction}
              rows={pageSizeT}
              totalRecords={totalCountT}
              onPageChange={(e) => handlePageChangeT(e)}
              template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
            />
          </div>
        </Dialog>
      </div>
    </>
  );
}
