import axiosInstance from './axiosInstance';
import {
  FETCH_TRANSACTIONS_SUCCESS,
  FETCH_TRANSACTIONS_FAILURE,
  FETCH_SINGLE_TRANSACTION_SUCCESS,
  FETCH_SINGLE_TRANSACTION_FAILURE,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_FAILURE,
  COUNT_TRANSACTIONS,
  COUNT_TRANSACTIONS_FAILURE,
  FETCH_ACCOUNT_TRANSACTION,
  COUNT_ACCOUNT_TRANSACTION,
  FETCH_ACCOUNT_TRANSACTION_FAILURE,
} from './actionTypes';

export const fetchTransactions =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.description = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/transactions', {
        params: params,
      });

      dispatch({
        type: FETCH_TRANSACTIONS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TRANSACTIONS_FAILURE,
        payload: error.response?.data || 'Failed to fetch transaction requests',
      });
    }
  };

export const getTransactions =
  (page = 1, limit = 10, user, totalCountT) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const response = await axiosInstance.get('/transactions', {
        params: {
          _sort: 'createdAt:desc',
          _limit: limit,
          _skip: skip,
          account: user,
        },
      });

      dispatch({
        type: FETCH_ACCOUNT_TRANSACTION,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: FETCH_ACCOUNT_TRANSACTION_FAILURE,
        payload: e.response?.data || 'Failed to fetch transaction requests',
      });
    }
  };

export const countTransaction = (user) => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/transactions', {
      params: {
        account: user,
        _count: true,
      },
    });

    dispatch({
      type: COUNT_ACCOUNT_TRANSACTION,
      payload: response.data,
    });
  } catch (e) {
    return false;
  }
};

export const countTransactions = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/transactions', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_TRANSACTIONS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_TRANSACTIONS_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchSingleTransaction = (requestId) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/transactions/${requestId}`);

    dispatch({
      type: FETCH_SINGLE_TRANSACTION_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_TRANSACTION_FAILURE,
      payload:
        error.response?.data || 'Failed to fetch single transaction request',
    });
  }
};

export const updateTransactions =
  (requestId, updatedData) => async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `/transactions/${requestId}`,
        updatedData
      );

      dispatch({
        type: UPDATE_TRANSACTION_SUCCESS,
        payload: response.data,
      });

      dispatch(fetchTransactions());
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSACTION_FAILURE,
        payload: error.response?.data || 'Failed to update transaction request',
      });
    }
  };
