import {
  FETCH_DISPATCHER_REQUESTS_SUCCESS,
  FETCH_DISPATCHER_REQUESTS_FAILURE,
  FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS,
  FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE,
  UPDATE_DISPATCHER_REQUEST_SUCCESS,
  UPDATE_DISPATCHER_REQUEST_FAILURE,
  COUNT_DISPATCHERREQUESTS,
  COUNT_DISPATCHERREQUESTS_FAILURE,
  FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS,
  FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
  COUNT_ACCOUNT_DISPATCH_REQUESTS,
  COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  dispatcherRequests: [],
  accountDispatchRequests: [],
  countAccountDispatchRequest: null,
  singleDispatcherRequest: null,
  error: null,
  countDispatcherRequests: null,
};

const dispatcherRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DISPATCHER_REQUESTS_SUCCESS:
      return {
        ...state,
        dispatcherRequests: action.payload,
        error: null,
      };

    case FETCH_DISPATCHER_REQUESTS_FAILURE:
      return {
        ...state,
        dispatcherRequests: [],
        error: action.payload,
      };

    case FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS:
      return {
        ...state,
        accountDispatchRequests: action.payload,
        error: null,
      };

    case FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE:
      return {
        ...state,
        accountDispatchRequests: [],
        error: action.payload,
      };

    case FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS:
      return {
        ...state,
        singleDispatcherRequest: action.payload,
        error: null,
      };

    case FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE:
      return {
        ...state,
        singleDispatcherRequest: null,
        error: action.payload,
      };

    case UPDATE_DISPATCHER_REQUEST_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case UPDATE_DISPATCHER_REQUEST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case COUNT_DISPATCHERREQUESTS:
      return {
        ...state,
        countDispatcherRequests: action.payload,
        error: null,
      };
    case COUNT_DISPATCHERREQUESTS_FAILURE:
      return {
        ...state,
        countDispatcherRequests: 0,
        error: action.payload,
      };

    case COUNT_ACCOUNT_DISPATCH_REQUESTS:
      return {
        ...state,
        countAccountDispatchRequests: action.payload,
        error: null,
      };
    case COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE:
      return {
        ...state,
        countAccountDispatchRequests: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default dispatcherRequestReducer;
