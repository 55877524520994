import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import React from 'react';
import movaexLogo from '../../assets/movaexLogo.png';
import '../../styles/login.css';

export default function ChangePassword() {
  return (
    <section>
      <div className='flex align-items-center my-auto  justify-content-center'>
        <div className='surface-card p-4 shadow-2 border-round w-full md:w-8 lg:w-7 xl:w-6'>
          <div className='text-center mb-5'>
            <img src={movaexLogo} alt='hyper' height={50} className='mb-3' />
            <div className='text-900 text-3xl font-medium mb-3'>
              Change Password
            </div>
          </div>

          <div>
            <label
              htmlFor='password'
              className='block text-900 font-medium mb-2'
            >
              Old Password
            </label>
            <InputText
              id='password'
              type='password'
              placeholder='Password'
              className='w-full mb-3 login__border_style'
            />

<label
              htmlFor='password'
              className='block text-900 font-medium mb-2'
            >
              Create New Password
            </label>
            <InputText
              id='password'
              type='password'
              placeholder='Password'
              className='w-full mb-3 login__border_style'
            />

            <label
              htmlFor='password'
              className='block text-900 font-medium mb-2'
            >
              Confirm New Password
            </label>
            <InputText
              id='password'
              type='password'
              placeholder='Password'
              className='w-full mb-3 login__border_style'
            />

            <Button
              label='Change Password'
              icon='pi pi-user'
              className='w-full button__style'
            />
          </div>
        </div>
      </div>
    </section>
  );
}
