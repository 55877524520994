import React from 'react';
import { InputText } from 'primereact/inputtext';
import { DataView } from 'primereact/dataview';

export default function Notifications() {
  const products = [
    {
      name: 'User 1',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
    {
      name: 'User 2',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
    {
      name: 'User 3',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
    {
      name: 'User 4',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
    {
      name: 'User 5',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
    {
      name: 'User 6',
      description: 'Request a booking 03 Jan 2024',
      image: 'bamboo-watch.jpg',
    },
  ];

  const itemTemplate = (product) => {
    return (
      <div className='col-12'>
        <div className='flex flex-column xl:flex-row xl:align-items-start p-4 gap-4'>
          <img
            className='w-9 sm:w-16rem xl:w-4rem shadow-1 block xl:block mx-auto border-round'
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.name}
          />
          <div className='flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4'>
            <div className='flex flex-column align-items-center sm:align-items-start gap-3'>
              <div className='text-1xl font-semibold text-900'>
                {product.name}
              </div>
              <div className='flex align-items-center gap-3'>
                <span className='flex align-items-center gap-2'>
                  <span className='font-normal'>{product.description}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='flex flex-wrap align-items-center justify-content-between gap-2'>
        <span className='text-lg text-900 font-bold'>Notifications</span>
        <span className='p-input-icon-left'>
          <i className='pi pi-search' />
          <InputText placeholder=' Search by name' />
        </span>
      </div>
    );
  };

  const header = renderHeader();
  return (
    <div className='card'>
      <DataView
        value={products}
        itemTemplate={itemTemplate}
        paginator
        rows={5}
        size='small'
        header={header}
      />
    </div>
  );
}
