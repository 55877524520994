// apiService.js

import axiosInstance from './axiosInstance';

const getSignedURL = async (uri) => {
  try {
    const response = await axiosInstance.get('/uploads/signedURL', {
      params: {
        uri: uri,
      },
    });

    return response.data;
  } catch (error) {
    throw error.response?.data || 'Failed to get signed URL';
  }
};

const uploadFile = async (file) => {
  try {
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('public', 'true');

    const response = await axiosInstance.post('/uploads', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    throw error.response?.data || 'Failed to upload file';
  }
};

export { getSignedURL, uploadFile };
