import axiosInstance from './axiosInstance';
import {
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_SINGLE_LOCATION_SUCCESS,
  FETCH_SINGLE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
} from './actionTypes';

export const fetchLocations = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/locations', {
      params: {
        _sort: 'createdAt:desc',
      },
    });

    dispatch({
      type: FETCH_LOCATIONS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_LOCATIONS_FAILURE,
      payload: error.response?.data || 'Failed to fetch locations',
    });
  }
};

export const fetchSingleLocation = (locationId) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/locations/${locationId}`);

    dispatch({
      type: FETCH_SINGLE_LOCATION_SUCCESS,
      payload: response.data,
    });

    return response.data;
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_LOCATION_FAILURE,
      payload:
        error.response?.data || 'Failed to fetch single location details',
    });
  }
};

export const updateLocation = (locationId, updatedData) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(
      `/locations/${locationId}`,
      updatedData
    );

    dispatch({
      type: UPDATE_LOCATION_SUCCESS,
      payload: response.data,
    });

    dispatch(fetchLocations());
  } catch (error) {
    dispatch({
      type: UPDATE_LOCATION_FAILURE,
      payload: error.response?.data || 'Failed to update location',
    });
  }
};
