import '../styles/accessDenied.css';
export default function AccessDenied() {
  return (
    <section className='access-denied-container'>
      <div className='access-denied-content'>
        <h1 className='access-denied-title'>Access Denied</h1>
        <p className='access-denied-message'>
          Oops! You don't have permission to access this page.
        </p>

        <a href='/login' className='access-denied-link'>
          Go back to login page
        </a>
      </div>
    </section>
  );
}
