import axiosInstance from './axiosInstance';
import {
  FETCH_TASK_MANAGEMENT_SUCCESS,
  FETCH_TASK_MANAGEMENT_FAILURE,
  COUNT_TASK_MANAGEMENT_SUCCESS,
  COUNT_TASK_MANAGEMENT_FAILURE,
  FETCH_TASK_PRIORITY_LIST_SUCCESS,
  FETCH_TASK_PRIORITY_LIST_FAILURE,
  COUNT_TASK_PRIORITY_LIST_SUCCESS,
  COUNT_TASK_PRIORITY_LIST_FAILURE,
} from './actionTypes';

export const fetchDispatchTasks =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.orderID = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/dispatchTasks', {
        params: params,
      });
      dispatch({
        type: FETCH_TASK_MANAGEMENT_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TASK_MANAGEMENT_FAILURE,
        payload: error.response?.data || 'Failed to fetch customers',
      });
    }
  };

export const countDispatchTasks = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/dispatchTasks', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_TASK_MANAGEMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_TASK_MANAGEMENT_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const countTaskPriorityList = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/dispatchTasks/priorityList', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_TASK_PRIORITY_LIST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_TASK_PRIORITY_LIST_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchTaskPriorityLists =
  (page = 1, limit = 10, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.note = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/dispatchTasks/priorityList', {
        params: params,
      });

      dispatch({
        type: FETCH_TASK_PRIORITY_LIST_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TASK_PRIORITY_LIST_FAILURE,
        payload: error.response?.data || 'Failed to fetch customers',
      });
    }
  };
