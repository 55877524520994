import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import {
  fetchDispatchTasks,
  countDispatchTasks,
} from '../../actions/taskManagementAction';

const TaskManagement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const products = useSelector((state) => state.dispatchTask.dispatchTasks);
  const totalCount = useSelector(
    (state) => state.dispatchTask.countDispatchTask
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchDispatchTasks(currentPage, pageSize, totalCount, searchTerm)
        );
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchDispatchTasks(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    dispatch(countDispatchTasks());
  }, [dispatch]);

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0 uppercase'>DISPATCH TASKS</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(fetchDispatchTasks(currentPage, pageSize, totalCount));
            }
          }}
          placeholder='Search for orderID'
        />
      </span>
    </div>
  );

  const paymentCollectedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'text-green-500 pi-check-circle': rowData.paymentCollected === true,
          'text-red-500 pi-times-circle': rowData.paymentCollected !== true,
        })}
      ></i>
    );
  };

  const deliveryStatusTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.status}
        severity={deliveryStatusSeverity(rowData)}
      ></Tag>
    );
  };

  const ownerCancellationTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'text-green-500 pi-check-circle': rowData.ownerCancellation === true,
          'text-red-500 pi-times-circle': rowData.ownerCancellation !== true,
        })}
      ></i>
    );
  };

  const deliveryStatusSeverity = (rowData) => {
    switch (rowData.status) {
      case 'COMPLETED':
        return 'success';

      case 'PROCESSING':
        return 'warning';

      case 'PENDING':
        return 'primary';

      case 'PARTIAL':
        return 'info';

      case 'FAILED':
        return 'danger';

      default:
        return null;
    }
  };

  const footer = `In total there ${
    totalCount > 0 ? `are ${totalCount} Tasks` : 'are 0 Task'
  }.`;
  return (
    <div className='card'>
      <DataTable
        value={products}
        size='small'
        rows={pageSize}
        dataKey='_id'
        tableStyle={{ minWidth: '100%' }}
        header={header}
        footer={footer}
      >
        <Column
          field='orderID'
          header='Order ID'
          body={(rowData) => `#${rowData.orderID}`}
        ></Column>
        <Column field='orderType' header='Order Type'></Column>
        <Column field='paymentOption' header='Payment Option'></Column>
        <Column
          field='paymentCollected'
          body={paymentCollectedBodyTemplate}
          header='Payment Collected'
        ></Column>
        <Column
          field='status'
          body={deliveryStatusTemplate}
          header='Status'
        ></Column>
        <Column
          field='ownerCancellation'
          body={ownerCancellationTemplate}
          header='Owner Cancellation'
        ></Column>
        <Column field='notes' header='Notes'></Column>
        <Column
          field='createdAt'
          header='Date'
          body={(rowData) =>
            new Date(rowData.createdAt).toLocaleString('en-US', {
              dateStyle: 'short',
              timeStyle: 'short',
            })
          }
        ></Column>
      </DataTable>
      <Paginator
        first={first}
        rows={pageSize}
        totalRecords={totalCount}
        onPageChange={(e) => handlePageChange(e)}
        template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
      />
    </div>
  );
};

export default TaskManagement;
