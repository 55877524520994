import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchWallets, countWallets } from '../../actions/walletActions';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { fetchUserById } from '../../actions/authActions';
import {
  getTransactions,
  countTransaction,
} from '../../actions/transactionActions';
import { Paginator } from 'primereact/paginator';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';

export default function Wallet() {
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [firstTransaction, setFirstTransaction] = useState(0);
  const [currentPageTransaction, setCurrentPageTransaction] = useState(1);
  const [pageSizeT] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const wallets = useSelector((state) => state.wallet.wallets);
  const totalCount = useSelector((state) => state.wallet.countWallets);
  const totalCountT = useSelector(
    (state) => state.transaction.countAccountTransaction
  );
  const transactions = useSelector(
    (state) => state.transaction.accountTransactions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(fetchWallets(currentPage, pageSize, totalCount, searchTerm));
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchWallets(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    if (selectedUserId) {
      dispatch(countTransaction(selectedUserId));
      dispatch(
        getTransactions(
          currentPageTransaction,
          pageSizeT,
          selectedUserId,
          totalCountT
        )
      );
    }
  }, [
    dispatch,
    selectedUserId,
    currentPageTransaction,
    pageSizeT,
    totalCountT,
  ]);

  useEffect(() => {
    dispatch(countWallets());
  }, [dispatch]);

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedProducts = await Promise.all(
          wallets.map(async (product) => {
            const accountUserPromise =
              product.account && product.account.length === 24
                ? await dispatch(fetchUserById(product.account))
                : product.account;

            const [accountUser] = await Promise.all([accountUserPromise]);

            return {
              id: product._id,
              userId: accountUser?._id,
              account: `${accountUser?.firstName ?? accountUser} ${
                accountUser?.lastName ?? ''
              }`,
              displayAvailableBalance: `NGN ${product?.displayAvailableBalance}`,
              createdAt: product?.createdAt
                ? new Date(product?.createdAt).toLocaleString('en-US', {
                    dateStyle: 'full',
                    timeStyle: 'short',
                  })
                : '',
              disabled: product?.disabled,
            };
          })
        );
        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dispatch, wallets]);

  const handlePageChangeT = (event) => {
    setFirstTransaction(event.first);
    setCurrentPageTransaction(event.page + 1);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-external-link'
          rounded
          outlined
          className='mr-2'
          onClick={() => {
            setVisible(true);
            setSelectedUserId(rowData.userId);
          }}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0 uppercase'>WALLETS</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(fetchWallets(currentPage, pageSize, totalCount));
            }
          }}
          placeholder='Search for id'
        />
      </span>
    </div>
  );

  const footer = `In total there ${
    totalCount > 0 ? `are ${totalCount} Wallets` : 'are 0 Wallet'
  }.`;

  const transactionFooter = `In total there ${
    totalCountT > 0 ? `are ${totalCountT} Transactions` : 'are 0 Transaction'
  }.`;
  return (
    <section>
      <div className='card'>
        <DataTable
          value={products}
          size='small'
          rows={pageSize}
          dataKey='id'
          tableStyle={{ minWidth: '100%' }}
          header={header}
          footer={footer}
        >
          <Column field='account' header='Account'></Column>
          <Column
            header='Available Balance'
            field='displayAvailableBalance'
          ></Column>
          <Column field='disabled' header='Disabled'></Column>
          <Column field='createdAt' header='Date'></Column>
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />
      </div>

      <div className='card flex justify-content-center'>
        <Dialog
          visible={visible}
          style={{ width: '70%' }}
          onHide={() => setVisible(false)}
        >
          <div className='card mt-5'>
            <DataTable
              header={`TRANSACTION HISTORY`}
              value={transactions}
              size='small'
              rows={pageSizeT}
              tableStyle={{ minWidth: '100%' }}
              footer={transactionFooter}
            >
              <Column field='description' header='Description'></Column>
              <Column field='type' header='Type'></Column>
              <Column
                field='displayAmount'
                header='Amount'
                body={(rowData) => `NGN ${rowData.displayAmount}`}
              ></Column>
              <Column
                field='createdAt'
                header='Date'
                body={(rowData) =>
                  new Date(rowData.createdAt).toLocaleString('en-US', {
                    dateStyle: 'full',
                    timeStyle: 'short',
                  })
                }
              ></Column>
            </DataTable>
            <Paginator
              first={firstTransaction}
              rows={pageSizeT}
              totalRecords={totalCountT}
              onPageChange={(e) => handlePageChangeT(e)}
              template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
            />
          </div>
        </Dialog>
      </div>
    </section>
  );
}
