import axiosInstance from './axiosInstance';
import {
  FETCH_DISPATCHER_REQUESTS_SUCCESS,
  FETCH_DISPATCHER_REQUESTS_FAILURE,
  FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS,
  FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE,
  UPDATE_DISPATCHER_REQUEST_SUCCESS,
  UPDATE_DISPATCHER_REQUEST_FAILURE,
  COUNT_DISPATCHERREQUESTS,
  COUNT_DISPATCHERREQUESTS_FAILURE,
  FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS,
  FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
  COUNT_ACCOUNT_DISPATCH_REQUESTS,
  COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
} from './actionTypes';

export const fetchDispatcherRequests =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.orderID = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/dispatchRequests', {
        params: params,
      });

      dispatch({
        type: FETCH_DISPATCHER_REQUESTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_DISPATCHER_REQUESTS_FAILURE,
        payload: error.response?.data || 'Failed to fetch dispatcher requests',
      });
    }
  };

export const fetchAccountDispatchRequests =
  (page = 1, limit = 10, user) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const response = await axiosInstance.get('/dispatchRequests', {
        params: {
          _sort: 'createdAt:desc',
          _limit: limit,
          _skip: skip,
          account: user,
        },
      });

      dispatch({
        type: FETCH_ACCOUNT_DISPATCH_REQUESTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
        payload: error.response?.data || 'Failed to fetch dispatcher requests',
      });
    }
  };

export const countDispatcherRequests = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/dispatchRequests', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_DISPATCHERREQUESTS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_DISPATCHERREQUESTS_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchCountAccountDispatchRequests = (user) => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/dispatchRequests', {
      params: { _count: true, account: user },
    });

    dispatch({
      type: COUNT_ACCOUNT_DISPATCH_REQUESTS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_ACCOUNT_DISPATCH_REQUESTS_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchSingleDispatcherRequest = (requestId) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/dispatchRequests/${requestId}`,
      {}
    );

    dispatch({
      type: FETCH_SINGLE_DISPATCHER_REQUEST_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SINGLE_DISPATCHER_REQUEST_FAILURE,
      payload:
        error.response?.data || 'Failed to fetch single dispatcher request',
    });
  }
};

export const updateDispatcherRequest =
  (requestId, updatedData) => async (dispatch) => {
    try {
      const response = await axiosInstance.put(
        `/dispatchRequests/${requestId}`,
        updatedData
      );

      dispatch({
        type: UPDATE_DISPATCHER_REQUEST_SUCCESS,
        payload: response.data,
      });

      dispatch(fetchDispatcherRequests());
    } catch (error) {
      dispatch({
        type: UPDATE_DISPATCHER_REQUEST_FAILURE,
        payload: error.response?.data || 'Failed to update dispatcher request',
      });
    }
  };
