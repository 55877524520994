import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { classNames } from 'primereact/utils';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Rating } from 'primereact/rating';
import {
  fetchDispatchers,
  updateDispatcher,
  countDispatchers,
} from '../../actions/authActions';
import { Avatar } from 'primereact/avatar';
import { Paginator } from 'primereact/paginator';
import { getSignedURL } from '../../actions/avatarService';
import { fetchAccountRating } from '../../actions/ratingAction';

export default function CustomerManagement() {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [products, setProducts] = useState([]);
  const [editableUser, setEditableUser] = useState(null);
  const [editableFirstName, setEditableFirstName] = useState('');
  const [editableLastName, setEditableLastName] = useState('');
  const [editableEmail, setEditableEmail] = useState('');
  const [editableStatus, setEditableStatus] = useState('');
  const [editableRole, setEditableRole] = useState('user');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);

  const totalCount = useSelector((state) => state.auth.countDispatcher);
  const users = useSelector((state) => state.auth.dispatchers);
  const dispatch = useDispatch();
  const Roles = ['dispatcher', 'user'];
  const Status = ['ACTIVE', 'DISABLED'];

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchDispatchers(currentPage, pageSize, totalCount, searchTerm)
        );
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchDispatchers(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    dispatch(countDispatchers());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedProducts = await Promise.all(
          (users || []).map(async (user) => {
            try {
              let ratingData;

              if (user._id && user._id.length === 24) {
                ratingData = await dispatch(fetchAccountRating(user._id));
              } else {
                ratingData = { _id: user._id };
              }

              return {
                id: user._id,
                overralRating: ratingData[0]?.overralRating || 0,
                totalRaters: ratingData[0]?.totalRaters || 0,
                firstName: user.firstName || '',
                lastName: user.lastName || '',
                email: user.email || '',
                avatar: { url: user.avatar?.url || '' },
                createdAt: new Date(user.createdAt).toLocaleString('en-US', {
                  dateStyle: 'short',
                  timeStyle: 'short',
                }),
                status: user.status || '',
                role: user.role || '',
                verified: user.verificationStatus || '',
              };
            } catch (error) {
              console.error('Error fetching data:', error);
              return {};
            }
          })
        );

        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, users]);

  useEffect(() => {
    if (selectedUser) {
      setEditableUser(selectedUser);
      setEditableFirstName(selectedUser.firstName || '');
      setEditableLastName(selectedUser.lastName || '');
      setEditableEmail(selectedUser.email || '');
      setEditableStatus(selectedUser.status || '');
      setEditableRole(selectedUser.role || '');
    }
  }, [selectedUser]);

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const handleEditClick = (rowData) => {
    setSelectedUser(rowData);
    setVisible1(true);
  };

  const handleUpdateUser = (e) => {
    e.preventDefault();
    const updatedData = {
      firstName: editableFirstName,
      lastName: editableLastName,
      email: editableEmail,
      status: editableStatus,
      role: editableRole,
    };
    dispatch(updateDispatcher(editableUser.id, updatedData));
    setVisible1(false);
  };

  const useAvatarFetch = (avatarUrl) => {
    const [avatarURL, setAvatarURL] = useState(null);
    const [loadingAvatar, setLoadingAvatar] = useState(false);

    useEffect(() => {
      const fetchAvatarURL = async () => {
        try {
          setLoadingAvatar(true);
          const { url } = await getSignedURL(avatarUrl);
          setAvatarURL(url);
        } catch (error) {
        } finally {
          setLoadingAvatar(false);
        }
      };

      if (avatarUrl) {
        fetchAvatarURL();
      }
    }, [avatarUrl]);

    return { avatarURL, loadingAvatar };
  };

  const footerContent = (
    <div>
      <Button
        label='No'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );
  const verifiedBodyTemplate = (rowData) => {
    return (
      <i
        className={classNames('pi', {
          'text-green-500 pi-check-circle': rowData.verified === 'SUCCESS',
          'text-red-500 pi-times-circle': rowData.verified !== 'SUCCESS',
        })}
      ></i>
    );
  };

  const footer = `In total there ${
    totalCount > 0 ? `are ${totalCount} Dispatchers` : 'are 0 Dispatcher'
  }.`;

  const statusOrderBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getProductSeverity(rowData)}></Tag>
    );
  };

  const getProductSeverity = (product) => {
    switch (product.status) {
      case 'ACTIVE':
        return 'success';

      case 'DISABLED':
        return 'danger';

      default:
        return null;
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-pencil'
          rounded
          outlined
          className='mr-2'
          onClick={() => handleEditClick(rowData)}
        />
        <Button
          icon='pi pi-trash'
          rounded
          outlined
          severity='danger'
          onClick={() => setVisible(true)}
        />
      </React.Fragment>
    );
  };
  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0 uppercase'>Manage Dispatchers</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(fetchDispatchers(currentPage, pageSize, totalCount));
            }
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );
  const AvatarComponent = ({ rowData }) => {
    const { avatarURL, loadingAvatar } = useAvatarFetch(rowData.avatar?.url);

    return (
      <div className='flex align-items-center gap-2'>
        {loadingAvatar ? (
          <span>Loading...</span>
        ) : (
          <>
            {rowData.avatar && rowData.avatar.url ? (
              <img
                alt={rowData.firstName}
                src={avatarURL}
                className='avatar___image'
              />
            ) : (
              <Avatar
                label={
                  rowData.firstName
                    ? rowData.firstName.slice(0, 1).toUpperCase()
                    : ''
                }
                size='medium'
                shape='circle'
              />
            )}
          </>
        )}
        <span>
          {rowData.firstName} <span></span> {rowData.lastName}
        </span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => (
    <AvatarComponent rowData={rowData} />
  );

  const ratingBodyTemplate = (user) => {
    return <Rating value={user.overralRating} readOnly cancel={false} />;
  };
  return (
    <>
      <div className='card'>
        <DataTable
          value={products}
          tableStyle={{ minWidth: '100%' }}
          dataKey='id'
          size='small'
          rows={pageSize}
          header={header}
          footer={footer}
        >
          <Column
            field='representative.name'
            header='Customer'
            body={representativeBodyTemplate}
          />

          <Column field='email' header='Email'></Column>
          <Column field='totalRaters' header='Total Rater'></Column>
          <Column
            field='overralRating'
            header='Overral Rating'
            body={ratingBodyTemplate}
          ></Column>
          <Column
            field='status'
            header='Status'
            body={statusOrderBodyTemplate}
          ></Column>
          <Column
            field='verified'
            header='Verified'
            dataType='boolean'
            bodyClassName='text-center'
            body={verifiedBodyTemplate}
          />
          <Column field='createdAt' header='CreatedAt'></Column>
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />

        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header='Confirm'
          modal
          footer={footerContent}
        >
          <div className='confirmation-content'>
            <i
              className='pi pi-exclamation-triangle mr-3'
              style={{ fontSize: '2rem' }}
            />
            {
              <span>
                Are you sure you want to delete the selected products?
              </span>
            }
          </div>
        </Dialog>

        <Dialog
          header='Update Customer'
          visible={visible1}
          style={{ width: '50vw' }}
          onHide={() => setVisible1(false)}
        >
          <form>
            <div className='grid w-11 m-auto'>
              <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
                <InputText
                  id='text'
                  type='text'
                  disabled
                  placeholder='Full Name'
                  className='w-full text-sm p-inputtext-md'
                  value={editableFirstName}
                  onChange={(e) => setEditableFirstName(e.target.value)}
                />
              </div>

              <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
                <InputText
                  id='text'
                  type='text'
                  disabled
                  placeholder='Full Name'
                  className='w-full text-sm p-inputtext-md'
                  value={editableLastName}
                  onChange={(e) => setEditableLastName(e.target.value)}
                />
              </div>

              <div className='col-12 md:col-12 lg:col-12 xl:col-12'>
                <InputText
                  id='email'
                  type='email'
                  disabled
                  placeholder='Email Address'
                  className='w-full text-sm p-inputtext-md'
                  value={editableEmail}
                  onChange={(e) => setEditableEmail(e.target.value)}
                />
              </div>
              <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
                <Dropdown
                  value={editableStatus}
                  onChange={(e) => setEditableStatus(e.value)}
                  options={Status}
                  placeholder='Select a Status'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-6 lg:col-6 xl:col-6'>
                <Dropdown
                  value={editableRole}
                  onChange={(e) => setEditableRole(e.value)}
                  options={Roles}
                  placeholder='Select a Role'
                  className='w-full'
                />
              </div>
              <div className='col-12 md:col-12 lg:col-12 text-center mt-5 xl:col-12'>
                <Button label='Update Customer' onClick={handleUpdateUser} />
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    </>
  );
}
