import React, { useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { Sidebar } from 'primereact/sidebar';
import { Avatar } from 'primereact/avatar';
import MovaexLogo from '../assets/movaexImage.png';
import '../styles/appBar.css';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../actions/authActions';
import avatar from '../assets/avatar.png';

export default function AppBAr({ toggleMenu }) {
  const [visibleRight, setVisibleRight] = useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const handleLogout = () => {
    dispatch(logout());
  };

  const items = [];

  const start = (
    <>
      <a href='/'>
        <img
          alt='Movaex Logo'
          src={MovaexLogo}
          height='40'
          className='mr-2 toolbar___image'
        ></img>
      </a>
    </>
  );
  const end = (
    <div className='flex justify-content-start justify-content-center'>
      <Avatar
        onClick={() => setVisibleRight(true)}
        shape='circle'
        image={avatar}
        className='mr-5 '
        size='large'
      />
      <button onClick={toggleMenu}>
        <i className='pi pi-bars' style={{ fontSize: '1.5rem' }}>
          {' '}
        </i>
      </button>
    </div>
  );

  return (
    <>
      <div className='card fixed top-0 left-0 w-full z-5 '>
        <Menubar
          model={items}
          start={start}
          center={end}
          className=''
          end={end}
        />
      </div>

      <Sidebar
        visible={visibleRight}
        position='right'
        onHide={() => setVisibleRight(false)}
      >
        <div className='mb-2 font-semibold'>Welcome </div>
        {user && (
          <div className='text-color-secondary font-medium mb-5'>
            {user.firstName} <span></span> {user.lastName}
          </div>
        )}

        <ul className='list-none m-0 p-0'>
          <li>
            <a
              href='/dashboard/profile'
              className='cursor-pointer no-underline flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150'
            >
              <span>
                <i className='pi pi-user text-xl text-primary'></i>
              </span>
              <div className='ml-3'>
                <span className='mb-2  text-gray-900 font-semibold'>
                  Profile
                </span>
              </div>
            </a>
          </li>

          <li>
            <a
              href='/dashboard/profile'
              className='cursor-pointer flex no-underline surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150'
            >
              <span>
                <i className='pi pi-cog text-xl text-primary'></i>
              </span>
              <div className='ml-3'>
                <span className='mb-2 text-gray-900 font-semibold'>
                  Settings
                </span>
              </div>
            </a>
          </li>
          <li>
            <span className='cursor-pointer flex no-underline surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150'>
              <span>
                <i className='pi pi-power-off text-xl text-primary'></i>
              </span>
              <div className='ml-3' onClick={handleLogout}>
                <span className='mb-2 text-gray-900 font-semibold'>
                  Sign Out
                </span>
              </div>
            </span>
          </li>
        </ul>
      </Sidebar>
    </>
  );
}
