import axiosInstance from './axiosInstance';
import {
  FETCH_USER_RATING_SUCCESS,
  FETCH_USER_RATING_FAILURE,
  COUNT_USER_RATING_SUCCESS,
  COUNT_USER_RATING_FAILURE,
} from './actionTypes';

export const fetchRatingsSuccess = (locations) => ({
  type: FETCH_USER_RATING_SUCCESS,
  payload: locations,
});

export const fetchRatingsFailure = (error) => ({
  type: FETCH_USER_RATING_FAILURE,
  payload: error,
});

export const fetchUserRatings =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.note = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/userRatings', {
        params: params,
      });

      dispatch(fetchRatingsSuccess(response.data));
    } catch (error) {
      dispatch(
        fetchRatingsFailure(
          error.response?.data || 'Failed to fetch GPS locations'
        )
      );
    }
  };

export const countRatings = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/userRatings', {
      params: { _count: true },
    });

    dispatch({
      type: COUNT_USER_RATING_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_USER_RATING_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};
