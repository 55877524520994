import {
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_DISPATCHERS_SUCCESS,
  FETCH_DISPATCHERS_FAILURE,
  FETCH_USER_BY_ID_SUCCESS,
  FETCH_USER_BY_ID_FAILURE,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  UPDATE_DISPATCHER_SUCCESS,
  UPDATE_DISPATCHER_FAILURE,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGOUT,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  FETCH_ADMINS_SUCCESS,
  FETCH_ADMINS_FAILURE,
  COUNT_CUSTOMERS,
  COUNT_CUSTOMERS_FAILURE,
  COUNT_DISPATCHERS,
  COUNT_DISPATCHERS_FAILURE,
  FETCH_ADMIN_SUPPORT_FAILURE,
  FETCH_ADMIN_SUPPORT_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  user: null,
  singleuser: null,
  error: null,
  isAuthenticated: false,
  isAdmin: false,
  adminCount: null,
  users: [],
  count: null,
  countDispatchers: null,
  admins: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNUP_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        error: null,
      };

    case SIGNUP_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
        isAdmin: action.payload.role === 'admin',
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        isAdmin: false,
        error: action.payload,
      };

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        error: null,
      };

    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_DISPATCHERS_SUCCESS:
      return {
        ...state,
        dispatchers: action.payload,
        error: null,
      };

    case FETCH_DISPATCHERS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        singleuser: action.payload,
        error: null,
      };

    case FETCH_USER_BY_ID_FAILURE:
      return {
        ...state,
        singleuser: null,
        error: action.payload,
      };

    case UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload.id,
        error: null,
      };

    case UPDATE_CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_DISPATCHER_SUCCESS:
      return {
        ...state,
        dispatchers: action.payload.id,
        error: null,
      };

    case UPDATE_DISPATCHER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case FETCH_ADMINS_SUCCESS:
      return {
        ...state,
        adminCount: action.payload,
        error: null,
      };

    case FETCH_ADMINS_FAILURE:
      return {
        ...state,
        error: action.payload,
      };

    case COUNT_CUSTOMERS:
      return {
        ...state,
        count: action.payload,
        error: null,
      };
    case COUNT_CUSTOMERS_FAILURE:
      return {
        ...state,
        count: 0,
        error: action.payload,
      };

    case COUNT_DISPATCHERS:
      return {
        ...state,
        countDispatcher: action.payload,
        error: null,
      };
    case COUNT_DISPATCHERS_FAILURE:
      return {
        ...state,
        countDispatcher: 0,
        error: action.payload,
      };

    case FETCH_ADMIN_SUPPORT_SUCCESS:
      return {
        ...state,
        admins: action.payload,
        error: null,
      };
    case FETCH_ADMIN_SUPPORT_FAILURE:
      return {
        ...state,
        admins: [],
        error: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        error: null,
      };

    default:
      return state;
  }
};

export default authReducer;
