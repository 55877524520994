import React, { useState } from 'react';
import { Paginator } from 'primereact/paginator';

import '../../styles/customerChat.css';
const chatData = [
  {
    id: 1,
    name: 'Customer Name 1',
    email: 'customer1@example.com',
    message: 'Hello, how can I help you today?',
  },
  {
    id: 2,
    name: 'Customer Name 2',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 3,
    name: 'Customer Name 3',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 4,
    name: 'Customer Name 4',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 5,
    name: 'Customer Name 5',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },

  {
    id: 6,
    name: 'Customer Name 6',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 7,
    name: 'Customer Name 7',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },

  {
    id: 8,
    name: 'Customer Name 8',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 9,
    name: 'Customer Name 9',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },

  {
    id: 10,
    name: 'Customer Name 10',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
  {
    id: 11,
    name: 'Customer Name 11',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },

  {
    id: 12,
    name: 'Customer Name 12',
    email: 'customer2@example.com',
    message: 'Another message from a different customer.',
  },
];

export default function CustomerChats() {
  const [first, setFirst] = useState(0);

  const onPageChange = (event) => {
    setFirst(event.first);
  };
  return (
    <>
      <div className='grid'>
        {chatData.map((chat) => (
          <div
            key={chat.id}
            className='col-12 sm:col-6 md:col-4 lg:col-4 xl:col-3'
          >
            <div className='chat-card border-1 border-400'>
              <div className='content'>
                <div className='flex justify-content-between flex-wrap'>
                  <div className='title'>{chat.name}</div>
                  <div>
                    <i
                      className='pi pi-trash'
                      style={{
                        fontSize: '1.5rem',
                        color: 'tomato',
                        cursor: 'pointer',
                      }}
                    ></i>
                  </div>
                </div>
                <div className='email'>{chat.email}</div>
                <p className='message'>{chat.message}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Paginator
        first={first}
        rows={10}
        className='mt-5 bg-none'
        totalRecords={50}
        onPageChange={onPageChange}
        template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
      />
    </>
  );
}
