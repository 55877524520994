import axiosInstance from './axiosInstance';
import { FETCH_RATING_SUCCESS, FETCH_RATING_FAILURE } from './actionTypes';

export const fetchAccountRating = (account) => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/ratings', {
      params: {
        account,
      },
    });

    dispatch({
      type: FETCH_RATING_SUCCESS,
      payload: response.data,
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: FETCH_RATING_FAILURE,
      payload: error.response?.data || 'Failed to fetch wallets',
    });
  }
};
