import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const userHasRequiredRole = user && (user.role === 'admin' || user.role === 'support');

  if (!isAuthenticated) {
    return <Navigate to='/login' state={{ from: location }} />;
  }

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to='/access-denied' />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
