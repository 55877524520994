import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { fetchUserById } from '../../actions/authActions';
import { fetchSingleLocation } from '../../actions/locationAction';
import {
  fetchDispatcherRequests,
  countDispatcherRequests,
} from '../../actions/dispatcherRequestActions';
import '../../styles/dispatcheManagement.css';
import { Paginator } from 'primereact/paginator';

export default function DispatchManagement() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatcherRequests = useSelector(
    (state) => state.dispatcherRequest.dispatcherRequests
  );

  const totalCount = useSelector(
    (state) => state.dispatcherRequest.countDispatcherRequests
  );

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchDispatcherRequests(currentPage, pageSize, totalCount, searchTerm)
        );
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchDispatcherRequests(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    dispatch(countDispatcherRequests());
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const updatedProducts = await Promise.all(
          dispatcherRequests.map(async (product) => {
            const accountUserPromise = product.account
              ? await dispatch(fetchUserById(product.account))
              : null;
            const customerEmail = await accountUserPromise;
            const customerPhone = await accountUserPromise;
            const email = customerEmail?.email;
            const c_phone = customerPhone?.phone;

            const dispatcherUserPromise = product.dispatcherID
              ? await dispatch(fetchUserById(product.dispatcherID))
              : null;
            const dispatcherEmail = await dispatcherUserPromise;
            const dispatcherPhone = await dispatcherUserPromise;
            const dEmail = dispatcherEmail?.email;
            const dPhone = dispatcherPhone?.phone;

            const locationPromise = product.pickUpLocation
              ? await dispatch(fetchSingleLocation(product.pickUpLocation))
              : null;

            const destinationsPromises =
              product.destinations?.map((destination) =>
                dispatch(fetchSingleLocation(destination.location))
                  .then((locationData) => {
                    return locationData.address;
                  })
                  .catch((error) => {
                    console.error('Error fetching single location:', error);
                    return '';
                  })
              ) || [];

            const [
              accountUser,
              dispatcherUser,
              pickUpLocation,
              allDestinations,
            ] = await Promise.all([
              accountUserPromise,
              dispatcherUserPromise,
              locationPromise,
              Promise.all(destinationsPromises),
            ]);

            return {
              id: product._id,
              customer:
                `${accountUser?.firstName} ${accountUser?.lastName}` || '',
              dispatcher: `${dispatcherUser?.firstName}` || '',
              date: new Date(product.createdAt).toLocaleString('en-US', {
                dateStyle: 'short',
                timeStyle: 'short',
              }),
              paymentStatus: product.paymentStatus || '',
              deliveryStatus: product.deliveryStatus || '',
              price: `NGN ${product.paymentAmount}` || 0,
              pickUpStatus: product.pickUpStatus || '',
              customerEmail: email || '',
              paymentOption: product.paymentOption || '',
              pickUpLocation: pickUpLocation.address || '',

              destinations: allDestinations.join(' ==> '),
              product: product.orderID || '#49eejjd',
              customertPhone: c_phone,
              dPhone: dPhone,
              dEmail: dEmail,
            };
          })
        );
        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, dispatcherRequests]);

  const handleViewClick = (clickID, rowData) => {
    setSelectedRowData({ clickID, ...rowData });
    setVisible1(true);
  };

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-eye'
          rounded
          outlined
          className='mr-2'
          onClick={() => handleViewClick(rowData.orderID, rowData)}
        />
      </React.Fragment>
    );
  };

  const footerContent = (
    <div>
      <Button
        label='No'
        icon='pi pi-times'
        onClick={() => setVisible(false)}
        className='p-button-text'
      />
      <Button
        label='Yes'
        icon='pi pi-check'
        onClick={() => setVisible(false)}
        autoFocus
      />
    </div>
  );

  const paymentStatusTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.paymentStatus}
        severity={getProductSeverity(rowData)}
      ></Tag>
    );
  };

  const deliveryStatusSeverity = (product) => {
    switch (product.deliveryStatus) {
      case 'COMPLETED':
        return 'success';

      case 'PROCESSING':
        return 'Warning';

      case 'PENDING':
        return 'Primary';

      case 'PARTIAL':
        return 'Info';

      case 'CANCELED':
        return 'danger';

      default:
        return null;
    }
  };

  const deliveryStatusTemplate = (rowData) => {
    return (
      <Tag
        value={rowData.deliveryStatus}
        severity={deliveryStatusSeverity(rowData)}
      ></Tag>
    );
  };

  const getProductSeverity = (product) => {
    switch (product.paymentStatus) {
      case 'COMPLETED':
        return 'success';

      case 'PENDING':
        return 'Primary';

      case 'PROCESSING':
        return 'Warning';

      case 'DEFERRED':
        return 'Info';

      case 'FAILED':
        return 'danger';

      default:
        return null;
    }
  };

  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0'>Manage Dispatch Requests</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(
                fetchDispatcherRequests(currentPage, pageSize, totalCount)
              );
            }
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );
  const footer = `In total there ${
    totalCount > 0
      ? `are ${totalCount} Dispatch Requests`
      : 'are 0 Dispatch Request'
  }.`;

  return (
    <>
      <div className='card'>
        <DataTable
          value={products}
          tableStyle={{ minWidth: '100%' }}
          dataKey='id'
          size='small'
          rows={pageSize}
          header={header}
          footer={footer}
        >
          <Column field='date' header='Date'></Column>
          <Column field='product' header='Order Id'></Column>
          <Column
            field='status'
            header='Delivery Status'
            body={deliveryStatusTemplate}
          ></Column>
          <Column
            field='status'
            header='Payment Status'
            body={paymentStatusTemplate}
          ></Column>

          <Column field='pickUpLocation' header='P. Location'></Column>
          <Column field='destinations' header='Destination'></Column>
          <Column field='price' header='price' />
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />

        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header='Confirm'
          modal
          footer={footerContent}
        >
          <div className='confirmation-content'>
            <i
              className='pi pi-exclamation-triangle mr-3'
              style={{ fontSize: '2rem' }}
            />
            {
              <span>
                Are you sure you want to delete the selected products?
              </span>
            }
          </div>
        </Dialog>

        <Dialog
          header=''
          visible={visible1}
          style={{ width: '70%' }}
          onHide={() => setVisible1(false)}
        >
          {selectedRowData ? (
            <>
              <section className=''>
                <div className='grid'>
                  <div className='col-4'>
                    <div className='profile-card'>
                      <div className='profile-details'>
                        <h2 className='profile-name'>
                          {selectedRowData?.customer}
                        </h2>
                        <p className='profile-job-title'>Customer</p>
                        <p className='profile-info'>
                          <a
                            href='mailto:johndoe@example.com'
                            className='avatar___href'
                          >
                            {' '}
                            Email: {selectedRowData?.customerEmail}
                          </a>
                        </p>
                        <div className='profile-info'>
                          <a href='tel:+1234567890' className='tel-button'>
                            Phone: {selectedRowData?.customertPhone}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='order-description'>
                      <h3>Order Description</h3>
                      <div className='order-details'>
                        <p>
                          <strong>Order ID:</strong>{' '}
                          {selectedRowData?.orderID || '# ldldr'}
                        </p>
                        <p>
                          <strong>Date:</strong> {selectedRowData?.date}
                        </p>
                        <p>
                          <strong>Delivery Status:</strong>{' '}
                          {selectedRowData?.deliveryStatus}
                        </p>
                        <p>
                          <strong>Payment Status:</strong>{' '}
                          {selectedRowData?.paymentStatus}
                        </p>
                        <p>
                          <strong>Pick Up Status:</strong>{' '}
                          {selectedRowData?.pickUpStatus}
                        </p>
                        <p>
                          <strong>Payment Option:</strong>{' '}
                          {selectedRowData?.paymentOption}
                        </p>
                        <p>
                          {' '}
                          <strong>Destination:</strong>
                          {selectedRowData?.destinations} Lagos
                        </p>
                        <p>
                          {' '}
                          <strong>Pickup locations:</strong>{' '}
                          {selectedRowData?.pickUpLocation}
                        </p>
                        <p>
                          <strong>Price:</strong> {selectedRowData.price}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='profile-card'>
                      <div className='profile-details'>
                        <h2 className='profile-name'>
                          {selectedRowData?.dispatcher}
                        </h2>
                        <p className='profile-job-title'>Dispatcher</p>
                        <p className='profile-info'>
                          <a
                            href='mailto:johndoe@example.com'
                            className='avatar___href'
                          >
                            {' '}
                            Email: {selectedRowData?.dEmail}
                          </a>
                        </p>
                        <div className='profile-info'>
                          <a href='tel:+1234567890' className='tel-button'>
                            Phone: {selectedRowData?.dPhone}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Dialog>
      </div>
    </>
  );
}
