import {
  FETCH_WALLET_FAILURE,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLETS_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS,
  FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE,
  COUNT_WALLETS,
  COUNT_WALLETS_FAILURE,
  FETCH_ACCOUNT_WALLETS,
  FETCH_ACCOUNT_WALLETS_FAILURE,
  FETCH_WALLETS_WITHDRAWAL_SUCCESS,
  FETCH_WALLETS_WITHDRAWAL_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  wallets: [],
  dispatchAccounts: [],
  countWallets: null,
  sortedWallets: [],
  walletAccount: [],
  error: null,
  allDispatcherWallets: [],
  withdrawalAccount: [],
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_SUCCESS:
      return {
        ...state,
        wallets: action.payload,
        error: null,
      };

    case FETCH_WALLET_FAILURE:
      return {
        ...state,
        wallets: [],
        error: action.payload,
      };

    case FETCH_WALLETS_SUCCESS:
      return {
        ...state,
        wallets: action.payload,
        sortedWallets: action.payload,
        error: null,
      };

    case FETCH_WALLETS_FAILURE:
      return {
        ...state,
        wallets: [],
        sortedWallets: [],
        error: action.payload,
      };

    case FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS:
      return {
        ...state,
        wallets: action.payload,
        dispatchAccounts: action.payload,
        error: null,
      };

    case FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE:
      return {
        ...state,
        wallets: [],
        dispatchAccounts: [],
        error: action.payload,
      };

    case COUNT_WALLETS:
      return {
        ...state,
        countWallets: action.payload,
        error: null,
      };
    case COUNT_WALLETS_FAILURE:
      return {
        ...state,
        countWallets: 0,
        error: action.payload,
      };

    case FETCH_ACCOUNT_WALLETS:
      return {
        ...state,
        walletAccount: action.payload,
        error: null,
      };

    case FETCH_ACCOUNT_WALLETS_FAILURE:
      return {
        ...state,
        walletAccount: null,
        error: action.payload,
      };

    case FETCH_WALLETS_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        wallets: [],
        withdrawalAccount: action.payload,
        error: null,
      };

    case FETCH_WALLETS_WITHDRAWAL_FAILURE:
      return {
        ...state,
        wallets: [],
        withdrawalAccount: [],
        error: action.payload,
      };

    default:
      return state;
  }
};

export default walletReducer;
