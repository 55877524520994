import axiosInstance from './axiosInstance';
import {
  FETCH_WALLET_FAILURE,
  FETCH_WALLET_SUCCESS,
  FETCH_WALLETS_SUCCESS,
  FETCH_WALLETS_FAILURE,
  FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS,
  FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE,
  COUNT_WALLETS,
  COUNT_WALLETS_FAILURE,
  FETCH_ACCOUNT_WALLETS,
  FETCH_ACCOUNT_WALLETS_FAILURE,
  FETCH_WALLETS_WITHDRAWAL_SUCCESS,
  FETCH_WALLETS_WITHDRAWAL_FAILURE,
} from './actionTypes';

export const fetchWalletsSuccess = (locations) => ({
  type: FETCH_WALLET_SUCCESS,
  payload: locations,
});

export const fetchWalletsFailure = (error) => ({
  type: FETCH_WALLET_FAILURE,
  payload: error,
});

export const fetchWallets =
  (page = 1, limit = 10, totalCount, searchTerm) =>
  async (dispatch) => {
    try {
      const skip = (page - 1) * limit;
      const params = {
        _sort: 'createdAt:desc',
        _limit: limit,
        _skip: skip,
        domain: 'default',
      };

      if (searchTerm) {
        const regex = new RegExp(searchTerm, 'i');
        params.account = `/${regex.source}/${regex.flags}`;
      }
      const response = await axiosInstance.get('/wallets', {
        params: params,
      });

      dispatch(fetchWalletsSuccess(response.data));
    } catch (error) {
      dispatch(
        fetchWalletsFailure(
          error.response?.data || 'Failed to fetch GPS locations'
        )
      );
    }
  };

export const countWallets = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/wallets', {
      params: { _count: true, domain: 'default' },
    });

    dispatch({
      type: COUNT_WALLETS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: COUNT_WALLETS_FAILURE,
      payload: error.response?.data || 'Failed to fetch customers',
    });
  }
};

export const fetchWalletsAccount = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/wallets', {
      params: {
        account: 'COLLECTIONS',
      },
    });

    dispatch({
      type: FETCH_WALLETS_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_WALLETS_FAILURE,
      payload: error.response?.data || 'Failed to fetch wallets',
    });
  }
};

export const fetchWithdrawalWalletsAccount = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/wallets', {
      params: {
        account: 'WITHDRAWALS',
      },
    });

    dispatch({
      type: FETCH_WALLETS_WITHDRAWAL_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_WALLETS_WITHDRAWAL_FAILURE,
      payload: error.response?.data || 'Failed to fetch wallets',
    });
  }
};

export const fetchWalletsdispatcherAccount = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get('/wallets', {
      params: {
        account: 'DISPATCH_PAYMENT',
      },
    });

    dispatch({
      type: FETCH_WALLETS_DISPATCH_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_WALLETS_DISPATCH_PAYMENT_FAILURE,
      payload: error.response?.data || 'Failed to fetch wallets',
    });
  }
};

export const wallets =
  (user, domain = 'default') =>
  async (dispatch) => {
    try {
      const response = await axiosInstance.get('/wallets', {
        params: {
          account: user,
          domain,
        },
      });

      dispatch({
        type: FETCH_ACCOUNT_WALLETS,
        payload: response.data,
      });

      return response.data;
    } catch (error) {
      dispatch({
        type: FETCH_ACCOUNT_WALLETS_FAILURE,
        payload: error,
      });
    }
  };
