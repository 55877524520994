import '../../styles/profile.css';
import React, { useState, useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { uploadFile } from '../../actions/avatarService';
import { updateCustomer, fetchUserById } from '../../actions/authActions';
import Avatar from '../../assets/avatar.png';

const Profile = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const userId = useSelector((state) => state.auth.user._id);

  const [showUploadSection, setShowUploadSection] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);

  const handlePencilClick = () => {
    setShowUploadSection(!showUploadSection);
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    const updatedUserData = {
      firstName: fetchedUser?.firstName || '',
      lastName: fetchedUser?.lastName || '',
      email: fetchedUser?.email || '',
      phone: fetchedUser?.phone || '',
    };

    dispatch(updateCustomer(userId, updatedUserData))
      .then(() => {
        showToast('success', 'Success', 'Profile updated successfully!');
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
        showToast('error', 'Error', 'Failed to update profile.');
      });
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail });
  };

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserById(userId))
        .then((fetchedUserData) => {
          setFetchedUser(fetchedUserData);
        })
        .catch((error) => {
          console.error('Error fetching user:', error);
        });
    }
  }, [dispatch, userId]);

  const handleFileUpload = async (selectedFile) => {
    try {
      const result = await uploadFile(selectedFile);
      const updatedUserData = {
        ...fetchedUser,
        avatar: result || null,
      };

      dispatch(updateCustomer(userId, updatedUserData))
        .then(() => {
          showToast('success', 'Success', 'Profile updated successfully!');
        })
        .catch((error) => {
          console.error('Error updating profile:', error);
          showToast('error', 'Error', 'Failed to update profile.');
        });
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className='profile-container w-6 m-auto '>
      <Toast ref={toast} />
      <div className='profile___avatar mx-auto'>
        <img
          src={
            fetchedUser?.avatar?.url &&
            typeof fetchedUser.avatar.url === 'string'
              ? fetchedUser.avatar.url
              : Avatar
          }
          alt='avatar'
        />

        <i
          className='pi pi-pencil'
          onClick={handlePencilClick}
          style={{ fontSize: '1.5rem' }}
        ></i>
      </div>

      {showUploadSection && (
        <div className='upload__image__section'>
          <FileUpload
            mode='basic'
            accept='image/*'
            onSelect={(e) => {
              handleFileUpload(e.files[0]);
            }}
          />
        </div>
      )}

      <form action='' className='mt-6'>
        <div className='grid'>
          <div className='col-6'>
            <label htmlFor='username' className='mb-8'>
              First name
            </label>
            <InputText
              id='username'
              value={fetchedUser?.firstName || ''}
              onChange={(e) =>
                setFetchedUser({ ...fetchedUser, firstName: e.target.value })
              }
              className='w-full'
            />
          </div>

          <div className='col-6'>
            <label htmlFor='username' className='mb-4'>
              Last name
            </label>
            <InputText
              id='username'
              value={fetchedUser?.lastName || ''}
              onChange={(e) =>
                setFetchedUser({ ...fetchedUser, lastName: e.target.value })
              }
              className='w-full'
            />
          </div>

          <div className='col-6'>
            <label htmlFor='username' className='mb-4'>
              Email Address
            </label>
            <InputText
              id='username'
              value={fetchedUser?.email || ''}
              onChange={(e) =>
                setFetchedUser({ ...fetchedUser, email: e.target.value })
              }
              className='w-full'
            />
          </div>

          <div className='col-6'>
            <label htmlFor='username' className='mb-4'>
              Phone Number
            </label>
            <InputText
              id='username'
              value={fetchedUser?.phone || ''}
              onChange={(e) =>
                setFetchedUser({ ...fetchedUser, phone: e.target.value })
              }
              className='w-full'
            />
          </div>

          <div className='col-12'>
            <Button
              label='Update Profile'
              icon='pi pi-user'
              className='w-full button__style'
              onClick={handleUpdateProfile}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Profile;
