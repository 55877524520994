import {
  FETCH_USER_RATING_SUCCESS,
  FETCH_USER_RATING_FAILURE,
  COUNT_USER_RATING_SUCCESS,
  COUNT_USER_RATING_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  userRatings: [],
  countUserRating: null,
  error: null,
};

const ratingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_RATING_SUCCESS:
      return {
        ...state,
        userRatings: action.payload,
        error: null,
      };

    case FETCH_USER_RATING_FAILURE:
      return {
        ...state,
        userRatings: [],
        error: action.payload,
      };

    case COUNT_USER_RATING_SUCCESS:
      return {
        ...state,
        countUserRating: action.payload,
        error: null,
      };
    case COUNT_USER_RATING_FAILURE:
      return {
        ...state,
        countUserRating: 0,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default ratingReducer;
