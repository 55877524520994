import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import {
  fetchTransactions,
  countTransactions,
} from '../../actions/transactionActions';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/transactionHistory.css';
import { fetchUserById } from '../../actions/authActions';
import { Paginator } from 'primereact/paginator';

export default function TransactionHistory() {
  const transactions = useSelector((state) => state.transaction.transactions);
  const totalCount = useSelector((state) => state.transaction.countTransaction);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [first, setFirst] = useState(0);

  useEffect(() => {
    dispatch(countTransactions());
  }, [dispatch]);

  useEffect(() => {
    if (searchTerm) {
      const timeoutId = setTimeout(() => {
        dispatch(
          fetchTransactions(currentPage, pageSize, totalCount, searchTerm)
        );
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(fetchTransactions(currentPage, pageSize, totalCount));
    }
  }, [searchTerm, dispatch, currentPage, pageSize, totalCount]);

  useEffect(() => {
    const fetchUserDetails = async (accountId) => {
      if (accountId && accountId.length === 24) {
        const accountUser = await dispatch(fetchUserById(accountId));
        return `${accountUser?.firstName} ${accountUser?.lastName}`;
      } else {
        return accountId;
      }
    };

    const fetchData = async () => {
      try {
        const updatedProducts = await Promise.all(
          transactions.map(async (product) => {
            const accountUser = await fetchUserDetails(product.account);

            return {
              id: product?._id,
              referenceId: product?.referenceId || '',
              type: product?.type || '',
              account: accountUser,
              referenceType: product?.referenceType || '',
              description: product?.description || '',
              currency: product?.currency || '',
              confirmMethod: product?.confirmMethod || '',
              displayBalanceBefore:
                ` NGN ${product?.displayBalanceBefore}` || '',
              displayBalanceAfter: ` NGN ${product?.displayBalanceAfter}` || '',
              displayAmount: ` NGN ${product?.displayAmount}` || '',
              createdAt: new Date(product?.createdAt).toLocaleString('en-US', {
                dateStyle: 'full',
                timeStyle: 'short',
              }),
              status: product?.status,
            };
          })
        );

        setProducts(updatedProducts);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch, searchTerm, transactions]);

  const handleViewClick = (clickID, rowData) => {
    setSelectedRowData({ clickID, ...rowData });
    setVisible(true);
  };

  const handlePageChange = (event) => {
    setFirst(event.first);
    setCurrentPage(event.page + 1);
  };

  const statusOrderBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getProductSeverity(rowData)}></Tag>
    );
  };

  const getProductSeverity = (product) => {
    switch (product.status) {
      case 'CONFIRMED':
        return 'success';

      case 'NEW':
        return 'info';

      case 'FAILED':
        return 'danger';

      default:
        return null;
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon='pi pi-eye'
          rounded
          outlined
          severity='primary'
          size='small'
          onClick={() => handleViewClick(rowData.orderID, rowData)}
        />
      </React.Fragment>
    );
  };

  const header = (
    <div className='flex flex-wrap gap-2 align-items-center justify-content-between'>
      <h4 className='m-0 uppacase'>Manage Transaction Histories</h4>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          value={searchTerm}
          onChange={(e) => {
            const value = e.target.value;
            setSearchTerm(value);

            if (!value) {
              dispatch(fetchTransactions(currentPage, pageSize, totalCount));
            }
          }}
          placeholder='Search...'
        />
      </span>
    </div>
  );

  const footer = `In total there ${
    totalCount > 0
      ? `are ${totalCount} Transaction Histories`
      : 'are 0 Transaction Historie'
  }.`;

  return (
    <>
      <div className='card'>
        <DataTable
          value={products}
          tableStyle={{ minWidth: '100%' }}
          dataKey='id'
          rows={pageSize}
          size='small'
          header={header}
          footer={footer}
        >
          <Column field='description' header='Description' />
          <Column field='type' header='Type'></Column>
          <Column
            field='status'
            header='Status'
            body={statusOrderBodyTemplate}
          ></Column>
          <Column field='account' header='Account' />
          <Column field='displayAmount' header='Amount' />
          <Column field='createdAt' header='Date' />
          <Column body={actionBodyTemplate} exportable={false}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={pageSize}
          totalRecords={totalCount}
          onPageChange={(e) => handlePageChange(e)}
          template='FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
        />

        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: '32rem' }}
          breakpoints={{ '960px': '75vw', '641px': '90vw' }}
          header='Payment Details'
          modal
        >
          {' '}
          {selectedRowData ? (
            <div className='confirmation-content'>
              <p>
                <strong>Account:</strong> {selectedRowData?.account}
              </p>

              <p>
                <strong>Confirm Method:</strong>{' '}
                {selectedRowData?.confirmMethod}
              </p>
              <p>
                <strong>Description:</strong> {selectedRowData?.description}
              </p>
              <p>
                <strong>Amount:</strong> {selectedRowData?.displayAmount}
              </p>
              <p>
                <strong>Display Balance Before:</strong>{' '}
                {selectedRowData?.displayBalanceBefore}
              </p>

              <p>
                <strong>Display Balance After:</strong>{' '}
                {selectedRowData?.displayBalanceAfter}
              </p>
              <p>
                <strong>Type:</strong> {selectedRowData?.type}
              </p>
              <p>
                <strong>Status:</strong> {selectedRowData?.status}
              </p>

              <p>
                <strong>Currency:</strong> {selectedRowData?.currency}
              </p>

              <p>
                <strong>Balance Before:</strong> NGN 0.00
              </p>
              <p>
                <strong>Reference Id:</strong> {selectedRowData?.referenceId}
              </p>
              <p>
                <strong>Reference Type:</strong>{' '}
                {selectedRowData?.referenceType}
              </p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Dialog>
      </div>
    </>
  );
}
