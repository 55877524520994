import React, { useState } from 'react';
import AppBAr from '../components/AppBar';
import { Outlet } from 'react-router-dom';
import SideMenu from '../components/SideMenu';

export default function DashBoard() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div className=''>
      <AppBAr toggleMenu={toggleMenu} className='' />
      <div className={`grid w-full md:w-11 lg:w-11 xl:w-full m-auto `}>
        <div className='col-2 md:col-4 lg:col-3 xl:col-2 mt-8'>
          <SideMenu isOpen={isMenuOpen} />
        </div>

        <div
          className={`${
            isMenuOpen
              ? 'col-12 md:col-12 lg:col-12 xl:col-12 '
              : ' col-12  md:col-10 lg:col-10 xl:col-10  mt-8 '
          }`}
        >
          <section className='children__routes'>
            <Outlet />
          </section>
        </div>
      </div>
    </div>
  );
}
