import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import React, { useState, useRef, useEffect } from 'react';
import movaexLogo from '../assets/movaexLogo.png';
import '../styles/login.css';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../actions/authActions';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const authError = useSelector((state) => state.auth.error);
  const toast = useRef(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checked, setChecked] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (toast.current) {
      if (authError && typeof authError === 'object') {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: authError.message || 'Login failed',
          life: 3000,
        });
      } else if (authError) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: authError || 'Login failed',
          life: 3000,
        });
      }
    }
  }, [isAuthenticated, user, navigate, authError]);

  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(login(email, password, toast, navigate));
  };

  return (
    <section>
      <div className='flex align-items-center my-auto h-screen justify-content-center'>
        <div className='surface-card p-4 shadow-2 border-round w-full md:w-6 lg:w-5 xl:w-4'>
          <div className='text-center mb-5'>
            <img src={movaexLogo} alt='hyper' height={50} className='mb-3' />
            <div className='text-900 text-3xl font-medium mb-3'>
              Welcome Back
            </div>
            <span className='text-600 font-medium line-height-3 '>
              Don't have an account?
            </span>
            <a
              href='/sign-up'
              className='font-medium no-underline ml-2 login__span__style cursor-pointer'
            >
              Create today!
            </a>
          </div>

          <div>
            <label htmlFor='email' className='block text-900 font-medium mb-2'>
              Email
            </label>
            <InputText
              id='email'
              type='text'
              required
              placeholder='Email address'
              className='w-full mb-3 login__border_style'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <label
              htmlFor='password'
              className='block text-900 font-medium mb-2'
            >
              Password
            </label>
            <InputText
              id='password'
              type='password'
              placeholder='Password'
              className='w-full mb-3 login__border_style'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className='flex align-items-center justify-content-between mb-6'>
              <div className='flex align-items-center'>
                <Checkbox
                  id='rememberme'
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                  className='mr-2 checkbox__style'
                />
                <label htmlFor='rememberme'>Remember me</label>
              </div>
              <a
                href='/forgot-password'
                className='font-medium no-underline ml-2 login__span__style text-right cursor-pointer'
              >
                Forgot your password?
              </a>
            </div>

            <Button
              label='Sign In'
              icon='pi pi-user'
              className='w-full button__style'
              onClick={handleLogin}
              disabled={!email || !password}
            />
          </div>
        </div>
      </div>
      <Toast ref={toast} />
    </section>
  );
}
