// store.js
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './rootReducer';

const storedUserInfo = localStorage.getItem('adminInfo');
const initialState = {
  auth: {
    user: storedUserInfo ? JSON.parse(storedUserInfo) : null,
    isAuthenticated: storedUserInfo !== null,
    error: null,
  },
};

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

export default store;
