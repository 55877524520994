import axios from 'axios';

const API_URL = process.env.REACT_APP_MOVAEX_API_URL;
const getTokenString = localStorage.getItem('adminInfo');
let authToken;

try {
  const adminInfo = JSON.parse(getTokenString);
  authToken = adminInfo?._token;
} catch (error) {
  console.error('Error parsing adminInfo:', error);
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
  },
});

export default axiosInstance;
